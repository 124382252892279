import React from "react";
import $ from "jquery";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { rpc } from "../fn/rpc";
import { Yufi, Url } from "../yufi";
import cn from "classnames";
import { Urls } from "../meta/urls";
import { isMobile } from "react-device-detect";

const redirect = (location: string) => {
  window.location.href = location;
};

class LoginApp {
  @observable username = "";
  @observable password = "";
  @observable login_error = false;
  @observable loading = false;

  constructor() {}

  @action
  update(field: string, value: string) {
    this[field] = value;
  }

  @action
  async login() {
    this.login_error = false;
    this.loading = true;
    try {
      let login_data = await rpc(Urls.login, {
        operation: "login",
        username: this.username,
        password: this.password
      });

      console.log("did this happen");
      console.log(login_data);
      if (login_data.redirect) {
        this.loading = false;
        redirect(login_data.redirect);
      }
    } catch (err) {
      console.log("the error handler", err);
      this.login_error = true;
      let element = $("error-notifier");
      if (element) {
        element.css("display", "block");
        element.html(err);

        setTimeout(() => {
          element.css("display", "none");
          this.login_error = false;
          this.loading = false;
        }, 2500);
      }
      console.log(err);
    }
  }
}

interface KeyboardEvent {
  key: string;
}

interface YufiProps extends React.Props<Login> {
  // yufi?: Yufi;
}

const Warning = observer((props: any) => (
  <article style={props.style} className="message is-danger">
    <div className="message-body">{props.msg}</div>
  </article>
));

@observer
class Login extends React.Component<YufiProps, {}> {
  // app:Login = null;
  // app = new Login()
  app: LoginApp;

  constructor(props: YufiProps) {
    super(props);
    this.app = new LoginApp();
  }

  _handleEnter = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      this.app.login();
    }
  };

  render() {
    // let { app } = this;
    return (
      <section className={"hero is-success is-fullheight"}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="column is-4 is-offset-4">
              <Warning
                style={{
                  visibility: this.app.login_error ? "visible" : "hidden"
                }}
                msg="Wrong Username / Password"
              />
              <div className="box">
                <form action={Urls.login} method="POST">
                  <div className="field">
                    <span className="title has-text-grey">Yufi :: Login </span>
                  </div>
                  <div className="field">
                    <input
                      className="input is-large"
                      placeholder="username"
                      type="text"
                      value={this.app.username}
                      onChange={e =>
                        this.app.update("username", e.target.value)
                      }
                    />
                  </div>

                  <div className="field">
                    <input
                      className="input is-large"
                      placeholder="password"
                      type="password"
                      value={this.app.password}
                      onChange={e =>
                        this.app.update("password", e.target.value)
                      }
                      onKeyPress={this._handleEnter}
                    />
                  </div>
                  <button
                    type="button"
                    className={cn("button is-block is-info is-large", {
                      "is-loading": this.app.loading
                    })}
                    onClick={e => this.app.login()}
                  >
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

interface InputProps {
  onKeyPress?(): any;
  store: any;
  field_name: string;
  field_type: string;
}

const Input = observer((props: InputProps) => {
  let { store, field_name, field_type } = props;
  return (
    <input
      className="input is-large"
      placeholder="password"
      type={field_type}
      value={store[field_name]}
      onChange={(e: any) => store.update(field_name, e.target.value)}
      onKeyPress={props.onKeyPress}
    />
  );
});

export { LoginApp, Login };
