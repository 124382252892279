import { observable, action } from "mobx";
// import * as React from "react";
import React from "react";

import { reaction } from "mobx";
import { observer } from "mobx-react";
// import * as moment from 'moment'
import moment from "moment";
import { Yufi, Url } from "../yufi";
import { rpc } from "../fn/rpc";
// import { range } from 'lodash/fp'
import { range, isEqual } from "lodash";

// import Badge from 'material-ui/Badge'

const cn: any = require("classnames");

// import { Button } from '../atoms/button'
// import { Menu } from '../molecules/menu'

// const width = 50
// const height = 50

const daysInMonth = (day: moment.Moment) => {
  // let day = moment()
  let first_day = day.startOf("month");
  console.log("within days in mont", first_day.format("YYYY-MM-DD"));
  let this_day = first_day;
  let days: DateInfo[] = [];
  let past_month = range(0, first_day.day()).map(d => {
    return {
      label: "-",
      date: first_day
    };
  });
  // console.log(past_month)
  days = days.concat(past_month);
  while (this_day.month() == first_day.month()) {
    days.push({ label: this_day.format("DD"), date: this_day });
    this_day = this_day.clone().add(1, "days");
  }
  while (days.length % 7 !== 0) {
    days.push({ label: "-", date: this_day });
    // days.push('-')
  }
  return days;
};

interface DateInfo {
  label: string;
  // date: moment
  date: any;
}

interface Routine {
  label: string;
  pk: string;
}

class Month {
  @observable viewing_day = moment();
  @observable days = daysInMonth(this.viewing_day);
  @observable denominator = 1;
  @observable completed_counts = {};
  @observable routine: Routine[] = [];
  @observable qs: string = "";
  yufi: Yufi;

  constructor(yufi: Yufi) {
    this.yufi = yufi;
    this.initialize(yufi);

    reaction(() => this.yufi.viewing_user, user => this.initialize(this.yufi));
  }

  @action
  change_month(direction: number) {
    console.log("change month called");
    this.viewing_day = this.viewing_day.clone().add(direction, "month");
    console.log(this.viewing_day.format("YYYY-MM-DD"));
    console.log(this.days);
    this.days = daysInMonth(this.viewing_day);
    console.log(this.days);
  }

  @action
  initialize(yufi: Yufi) {
    console.log("qs", this.qs);
    rpc("/", {
      operation: "retrieve_month",
      qs: this.qs,
      viewing_user: yufi.viewing_user
    }).then(
      action((data: any) => {
        console.log(data);
        this.completed_counts = data.completed_day_counts;
        this.denominator = data.denominator;
        this.routine = data.all_tasks;
      })
    );
  }

  @action
  update(field: string, value: string | boolean) {
    this[field] = value;
  }

  @action
  filter(qs: string) {
    this.qs = qs;
    this.initialize(this.yufi);
  }
}

// const register = (yufi: Yufi) => {
//   let app = new Month()
//   window['month'] = app
//   // yufi.app = app
//   return app
// }

// const jss: any = require('jss')
// const preset: any = require('jss-preset-default')

// let j2 = jss
//   .create({
//     generateClassName: (stylesStr: any, rule: any, sheet: any) => {
//       return __filename + stylesStr + rule + sheet
//     }
//   })
//   .setup(preset.default())

// Create your style.
const style = {
  myButton: {
    // color: 'green'
  },
  calendar: {
    background: "#fff",
    border: "none",
    borderRadius: 2,
    display: "block",
    minWidth: "20rem",
    textAlign: "center",
    maxWidth: "100%"
  },
  calendarNav: {
    alignItems: "center",
    background: "#55B1E3",
    // background: '#00d1b2',
    color: "#fff",
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1.25rem",
    padding: ".5rem"
  },
  calendarContainer: {
    boxSizing: "inherit"
  },
  calendarHeader: {
    background: "#3AA6DF",
    color: "#fff",
    fontSize: ".75rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: ".2rem 0",
    height: "3rem"
  },
  calendarDate: {
    flex: "0 0 14.28%",
    maxWidth: "14.28%",

    borderBottom: "none",
    borderRight: "none",
    display: "flex",
    flexDirection: "column",
    height: "6rem"
    // padding: 0
  },
  calendarBody: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    // padding: 0,
    color: "#7a7a7a"
  }
};

// Compile styles, apply plugins.

let sheet: any;
if (window["jal"]) {
  let jal = window["jal"];
  sheet = jal.createStyleSheet(style);
} else {
  const jss: any = require("jss");
  const preset: any = require("jss-preset-default");
  let jal = jss.create().setup(preset.default());
  sheet = jal.createStyleSheet(style);
  window["jal"] = jal;
}
// const sheet = jal.createStyleSheet(style)
// If you want to render on the client, insert it into DOM.
sheet.attach();

// console.log('sheet', sheet)
// console.log('sheet', sheet.classes)

const css = sheet.classes;

const FilterPanel = observer(({ month }: { month: Month }) => (
  <div className="panel">
    <p className="panel-heading">{"filters"}</p>

    {
      // <div className="panel-block">
      //   <p className="control has-icons-left">
      //     <input className="input is-small" type="text" placeholder="search" />
      //     <span className="icon is-small is-left">
      //       <i className="fa fa-search" />
      //     </span>
      //   </p>
      // </div>
    }

    <p className="panel-tabs">
      <a className="is-active" onClick={e => month.filter("")}>
        all
      </a>
      {
        // <a>work</a>
        // <a>health</a>
        // <a>finance</a>
      }
    </p>

    {month.routine.map(r => (
      <a
        className={cn("panel-block", { "is-active": r.pk == month.qs })}
        onClick={e => month.filter(r.pk)}
      >
        {
          // is-active
          // <span className="panel-icon">
          //   <i className="fa fa-book" />
          // </span>
        }
        {r.label}
      </a>
    ))}

    {
      // <a className="panel-block">
      //   <span className="panel-icon">
      //     <i className="fa fa-book" />
      //   </span>
      //   cardio
      // </a>
    }
  </div>
));

interface YufiProps extends React.Props<MonthView> {
  yufi: Yufi;
}

class MonthView extends React.Component<YufiProps> {
  month: Month;
  yufi: Yufi;

  constructor(props: YufiProps, state: {}) {
    super(props);
    this.yufi = props.yufi;
    this.month = new Month(this.yufi);
  }

  render() {
    let month = this.month;
    let yufi = this.yufi;
    return (
      <div className="columns">
        <div className="column">
          <FilterPanel month={month} />
        </div>
        <div className="column is-three-quarters">
          <Calendar yufi={yufi} month={month} />
        </div>
        {
          // <div classname="columns">
          //   <div classname="column is-four-fifths">
          //   </div>
          // </div>
        }
      </div>
    );
  }
}

// const MonthView = observer(
//   ({ yufi }: { yufi: Yufi }, { month }: { month: Month }) => (
//     <div className="columns">
//       <div className="column">
//         <FilterPanel month={month} />
//       </div>
//       <div className="column is-three-quarters">
//         <Calendar yufi={yufi} month={month} />
//       </div>
//       {
//         // <div classname="columns">
//         //   <div classname="column is-four-fifths">
//         //   </div>
//         // </div>
//       }
//     </div>
//   )
// )

let get_shade = (month: Month, day: any) => {
  let test = month.completed_counts[day.date.format("YYYY-MM-DD")];
  if (!test) {
    return {};
  }
  let opacity =
    month.completed_counts[day.date.format("YYYY-MM-DD")] / month.denominator;
  let color = `rgba(0,128,0,${opacity})`;

  // console.log(opacity, color)
  return {
    // no idea why that doesn't work
    // fontWeight: 'bold',
    background: test ? color : undefined

    // opacity: test ? opacity : 1
  };
};
import { CSSProperties } from "react";

let get_color = (month: Month, day: any): CSSProperties => {
  let result = get_shade(month, day);
  let today = day.date.isSame(moment(), "day");
  if (isEqual(result, {})) {
    return {
      fontWeight: today ? "bold" : "normal",
      fontSize: today ? "2rem" : undefined
    };
  } else {
    return {
      fontSize: today ? "2rem" : undefined,
      fontWeight: today ? "bold" : "normal",
      color: "white",
      textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"
    };
  }
};

const Calendar = observer(({ yufi, month }: { yufi: Yufi; month: Month }) => (
  <div className={css.calendar}>
    <div className={css.calendarNav}>
      <div className="calendar-nav-left">
        <button
          onClick={e => month.change_month(-1)}
          className="button is-text"
          style={{
            textDecoration: "none"
          }}
        >
          <i className="fa fa-chevron-left" />
        </button>
      </div>
      <div>{month.viewing_day.format("MMM YYYY")}</div>
      <div className="calendar-nav-right">
        <button
          onClick={e => month.change_month(1)}
          className="button is-text"
          style={{
            textDecoration: "none"
          }}
        >
          <i className="fa fa-chevron-right" />
        </button>
      </div>
    </div>

    <Labels />
    <div className={css.calendarBody}>
      {month.days.map((day, index) => (
        <div
          className={css.calendarDate}
          style={get_shade(month, day)}
          onClick={e => yufi.route(Url.day, { day: day })}
        >
          <span style={get_color(month, day)}>
            {day.label}
            {
              // <div>
              //   <Badge badgeContent={4} color="primary">
              //     <span className="icon">
              //       <i className="fa fa-facebook-official" aria-hidden="true" />
              //     </span>
              //   </Badge>
              // </div>
            }

            {
              // todo
              // <div>3 todos</div>
              // <div>1 Note</div>
            }
          </span>
        </div>
      ))}
    </div>
  </div>
));

// daysInMonth()

const Labels = () => (
  <div className={css.calendarHeader}>
    {["S", "M", "T", "W", "T", "F", "S"].map((d, i) => (
      <span className={css.calendarDate} key={d + i}>
        {d}
      </span>
    ))}
  </div>
);

export { Month, MonthView, Calendar };
