import * as React from 'react'
import { Icon } from './icon'
const cn: any = require('classnames')

// declare namespace JSX {
//   interface IntrinsicElements {
//     [elemName: string]: any
//   }
// }

class ButtonProps {
  onClick? = (event: any) => {
    console.log('clicked')
  }
  children?: any
  loading?: boolean = false
  done?: boolean = false
}

const Button = (props: ButtonProps) => (
  <span
    className={cn('button', {
      'is-loading': props.loading
    })}
    {...props}
    style={{
      padding: 10
      // textDecorationLine: 'underline',
      // color: 'green'
    }}
  >
    {props.done && <Icon name="fa-check" />}
    {'  '}
    {props.children}
  </span>
)

// const Button = (props: any) => (
//   <div {...props} className={cn('button', { 'is-loading': props.loading })}>
//     {props.children}
//   </div>
// )

export { Button }
