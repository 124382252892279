import React from "react";
import { observer } from "mobx-react";
import { Bu } from "../atoms/bulma";
import cn from "classnames";

const Header = observer((props: any) => (
  <nav
    className="navbar is-light has-shadow"
    role="navigation"
    aria-label="main navigation"
  >
    <Bu container>
      <Bu navbar-brand>
        <Bu navbar-item>
          <span className="icon is-large ">
            <i className="fa fa-cubes fa-2x" aria-hidden="true" />
          </span>
          <h1 className="title logo">
            Yu
            <span className="cursive">f</span>i
          </h1>
          {}
        </Bu>
      </Bu>

      <Bu navbar-menu is-active>
        {props.yufi.user === "" && props.yufi.loggedin == false && (
          <Bu navbar-end>
            <Bu navbar-item>
              <button
                className="button"
                onClick={e => props.yufi.route(props.Url.login)}
              >
                Log In
              </button>
            </Bu>
          </Bu>
        )}
        {props.yufi.user !== "" && (
          <Bu navbar-end>
            <Bu navbar-item />
            <div
              className={cn("navbar-item has-dropdown is-hoverable", {
                // 'has-dropdown is-active': props.yufi.dropdown_visible
              })}
            >
              <a
                className="navbar-link"
                onClick={e => props.yufi.update("dropdown_visible", true)}
              >
                {props.yufi.user}
              </a>
              <div className="navbar-dropdown">
                <a
                  onClick={e =>
                    props.yufi.control_modal(props.ModalType.questions)
                  }
                  className="navbar-item"
                >
                  Questions / ?
                </a>
                <a
                  className="navbar-item"
                  href="https://trello.com/b/GaWGKgZm/yufi"
                >
                  Feedback
                </a>

                <a
                  className="navbar-item"
                  href="/settings/"
                  // onClick={e => {
                  //   // props.yufi.control_modal(props.ModalType.settings);
                  //   // e.nativeEvent.stopImmediatePropagation();
                  // }}
                >
                  <i className="fa fa-cog" aria-hidden="true" />
                  {"   Settings"}
                </a>

                <a
                  className="navbar-item"
                  href={window.location.origin + "/overlord/logout/"}
                >
                  Logout
                </a>
              </div>
            </div>
          </Bu>
        )}
      </Bu>
    </Bu>
  </nav>
));

export { Header };
