const cn: any = require('classnames')

const get_sheet = (style: any) => {
  let jal: any
  let sheet: any
  if (window['jal']) {
    jal = window['jal']
    sheet = jal.createStyleSheet(style)
  } else {
    const jss: any = require('jss')
    const preset: any = require('jss-preset-default')
    jal = jss.create().setup(preset.default())
    sheet = jal.createStyleSheet(style)
    window['jal'] = jal
  }
  return sheet
}

export { get_sheet, cn }
