import React from "react";
// import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
// import * as moment from 'moment'
import moment from "moment";
import { rpc } from "../fn/rpc";
// import { Editor, EditorState, ContentState, RichUtils } from 'draft-js'
import { get_sheet } from "../atoms/styling";
import { debounce } from "lodash";
// import * as marked from 'marked'

// import { Editor as Editor2 } from 'slate-react'
// import { Value } from 'slate'
// import
// import ReactQuill from 'react-quill'
const ReactQuill: any = require("react-quill");

// import { Yufi } from '../yufi'
import { Day } from "../pages/day";
// import { MarkdownShortcuts } from '../thirdparty/quillmarkdown'
// import { Quill } from 'react-quill'

const { Quill } = ReactQuill;
import { MarkdownShortcuts } from "../thirdparty/quillmarkdown";
// const MarkdownShortcuts = require('../thirdparty/quillmarkdown')
Quill.register("modules/markdownShortcuts", MarkdownShortcuts, true);

enum JournalSave {
  waiting,
  saving,
  saved
}

const style = {
  "@global": {
    ".public-DraftEditor-content": {
      minHeight: 300
    }
  }
};

const sheet = get_sheet(style);
sheet.attach();
// const css = sheet.classes

class JournalController {
  @observable state = JournalSave.waiting;
  @observable contents: string = "";
  @observable states = JournalSave;
  @observable parent: any = null;
  @observable date: moment.Moment = moment();
  @observable day: Day;
  // @observable editorState = EditorState.createEmpty()
  // @observable autosaver
  @observable dirty: boolean = false;

  // @observable
  // slate_value = Value.fromJSON({
  //   document: {
  //     nodes: [
  //       {
  //         kind: 'block',
  //         type: 'paragraph',
  //         nodes: [
  //           {
  //             kind: 'text',
  //             leaves: [
  //               {
  //                 text: 'A line of text in a paragraph.'
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //     ]
  //   }
  // })

  // @action
  // slate_change = (change: any) => {
  //   // console.log(value)
  //   this.slate_value = change.value
  // }

  // @observable called: boolean = false

  constructor(day: Day) {
    this.day = day;
    this.date = day.date_to_view;
    console.log("this was called jc");
  }

  // @action
  // onChange = (editorState: EditorState) => {
  //   // console.log('called?', editorState.contentstate)
  //   // debugger
  //   this.editorState = editorState
  //   this.contents = editorState.getCurrentContent().getPlainText()
  //   // editorState.get
  //   this.dirty = true
  //   // setTimeout(() => {
  //   this.autosaver()
  //   // }, 1000)
  // }

  @action
  autosaver = debounce(() => {
    // this.called = true
    if (this.dirty) {
      // alert(
      //   'saving' + '\n' + this.date.format('YYYY-MM-DD') + '\n' + this.contents
      // )
      // let foo = this.editorState.getCurrentContent().getPlainText()
      // console.log('saving', this.date)
      this.update_journal();
    }
  }, 500);

  @action
  transition = (state: JournalSave) => {
    this.state = state;
  };

  // _onBoldClick = () => {
  //   this.onChange(RichUtils.toggleInlineStyle(this.editorState, 'BOLD'))
  // }

  // handleKeyCommand = (command: any, editorState: EditorState) => {
  //   const newState = RichUtils.handleKeyCommand(editorState, command)
  //   if (newState) {
  //     this.onChange(newState)
  //     return 'handled'
  //   }
  //   return 'not-handled'
  // }

  @action
  set_journal(contents: string) {
    console.log(contents);
    this.contents = contents;
    // let cs = ContentState.createFromText(contents)
    // this.editorState = EditorState.createWithContent(cs)
  }

  @action
  update_journal = () => {
    this.transition(this.states.saving);
    rpc("/", {
      operation: "update_journal",
      date: this.date,
      journal: this.contents
    }).then(() => {
      this.dirty = false;
      setTimeout(
        action(() => {
          this.transition(this.states.saved);
          setTimeout(
            action(() => {
              this.transition(this.states.waiting);
            }),
            1000
          );
        }),
        1000
      );
    });
  };
}

interface JournalProps extends React.Props<React.Component> {
  jc: JournalController;
}

// const LineEditor = ()=>{
//
// }

// const initialValue =

const Journal = observer(({ jc }: { jc: JournalController }) => (
  <div>
    <div className="title is-1">Journal</div>
    <div className="subtitle is-3">{"A daily scratchpad"}</div>
    <div className="card">
      <div className="card-content">
        <ReactQuill
          readOnly={jc.day.yufi.viewing_user != null}
          modules={{
            markdownShortcuts: {}
          }}
          value={jc.contents}
          style={{
            minHeight: 300
          }}
          onKeyPress={action((value: any) => {
            //better than on change, which seeems
            // to fire in bizarre instances
            jc.autosaver();
          })}
          onChange={action((value: any) => {
            jc.contents = value;
            jc.dirty = true;
            // jc.autosaver()
          })}
        />
        {
          // <ReactQuill
          //   modules={{
          //     // markdownShortcuts: {}
          //   }}
          //   value={jc.contents}
          //   style={{
          //     minHeight: 300
          //   }}
          //   onChange={action((value: any) => {
          //     jc.contents = value
          //     // jc.dirty = true
          //     // jc.autosaver()
          //   })}
          // >
          //   <div style={{ minHeight: 300 }} />
          // </ReactQuill>
          // <div className="box journal" style={{ minHeight: 300, maxWidth: 750 }}>
          // </div>
          // <textarea
          //   value={jc.contents}
          //   onChange={action((e: any) => (jc.contents = e.target.value))}
          // />
          // <div dangerouslySetInnerHTML={{ __html: marked(jc.contents) }} />
          // <button onClick={jc._onBoldClick}>Bold</button>
          // <Editor2 onChange={jc.slate_change} value={jc.slate_value} />
        }
        {
          // <div contentEditable={true} onChange={(e: any) => console.log(e)}>
          //   <ul style={{ listStyle: 'circle' }}>
          //     <li>1</li>
          //     <li>2</li>
          //     <li>3</li>
          //   </ul>
          // </div>
        }
        {
          // <textarea
          //   className="textarea content"
          //   name=""
          //   id=""
          //   cols={30}
          //   rows={10}
          //   value={jc.contents}
          //   onChange={action((e: any) => (jc.contents = e.target.value))}
          // />
        }
      </div>
      <div className="card-footer">
        <a onClick={e => jc.update_journal()} className="card-footer-item">
          {jc.state == JournalSave.waiting && "Save"}
          {jc.state == JournalSave.saving && "Saving..."}
          {jc.state == JournalSave.saved && "Saved!"}
        </a>
      </div>
    </div>
  </div>
));

const Journal2 = observer(({ jc }: { jc: JournalController }) => (
  <div className="card">
    <div className="card-header-title">
      <div className="subtitle">{"Scratchpad & Journal"}</div>
    </div>
    <div className="card-content">
      <div className="box" style={{ minHeight: 300, maxWidth: 750 }}>
        {
          // <button onClick={jc._onBoldClick}>Bold</button>
          // <Editor
          //   // placeholder={'...'}
          //   // style={{ minHeight: 300 }}
          //   editorState={jc.editorState}
          //   onChange={jc.onChange}
          //   handleKeyCommand={jc.handleKeyCommand}
          // />
        }
      </div>
      {
        // <textarea
        //   className="textarea content"
        //   name=""
        //   id=""
        //   cols={30}
        //   rows={10}
        //   value={jc.contents}
        //   onChange={action((e: any) => (jc.contents = e.target.value))}
        // />
      }
    </div>
    <div className="card-footer">
      <a onClick={e => jc.update_journal()} className="card-footer-item">
        {jc.state == JournalSave.waiting && "Save"}
        {jc.state == JournalSave.saving && "Saving..."}
        {jc.state == JournalSave.saved && "Saved!"}
      </a>
    </div>
  </div>
));
Journal2;

export { Journal, JournalController, JournalProps };
