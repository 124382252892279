import { observable, action, reaction } from "mobx";
import React from "react";
import { observer } from "mobx-react";
import { Month, MonthView, Calendar } from "./pages/month";
import { Alpha } from "./pages/alpha";
import { Modal } from "./molecules/modal";
// const cn: any = require("classnames");
// import cn from "classnames";
// import { Bu } from "./atoms/bulma";
import { Day, Task, Todo, DayView } from "./pages/day";
// import { RoutineRow } from "./molecules/routine";
import { Routine } from "./molecules/routine";
// import { SwipeRoutine } from "./molecules/swipe_routine";
// import { TodoCheckmark } from "./molecules/todos";
import { Menu } from "./molecules/menu";
// import { Journal } from "./molecules/journal";
import { BrowserView, MobileView } from "react-device-detect";

import * as Sentry from "@sentry/browser";

// useStrict(true)

enum Url {
  login,
  day,
  month,
  create,
  alpha
  // logout
}

interface Friend {
  friend__username: string;
  friend__id: number;
}

interface InitialProps {
  user: string;
  authenticated: boolean;
  google_calendar_authenticated: boolean;
  gcal_auth_url: string;
  friends: any[];
}

enum ModalType {
  none,
  feedback,
  questions,
  settings
}

class Yufi {
  @observable loggedin: boolean = false;
  @observable timer = 0;
  @observable page: Url = Url.month;
  // it would be nice to make this a more
  //exclicit type
  @observable app?: object = {};
  @observable user: string = "";
  @observable dropdown_visible: boolean = false;
  @observable page_data: object = {};
  @observable modal = ModalType.none;
  @observable django_props: InitialProps;
  @observable friends: Friend[] = [];
  @observable viewing_user: number | null = null;
  @observable day: Day;
  // @observable day
  // @observable jss: any = jal

  constructor(props: any) {
    console.log("provided props", props);
    this.django_props = props;
    // let initial_props = 'initial_props'
    // let django_props: InitialProps = window[initial_props]
    // this.django_props = django_props
    // this.django_props = undefined
    this.update("loggedin", this.django_props.authenticated);
    if (this.django_props.authenticated) {
      console.log("am i authenticated");
      this.page = Url.month;
      // this.page = Url.day
      //   // this.page = Url.create
      //   // this.page = Url.alpha
      this.user = this.django_props.user;
      this.friends = this.django_props.friends;
      //   // alert(django_props.friends)
    }
  }

  @action
  view_friend(f: Friend | null) {
    // console.log(f)
    if (f) {
      this.viewing_user = f.friend__id;
    } else {
      this.viewing_user = null;
    }
  }

  @action
  control_modal(modal: ModalType) {
    this.modal = modal;
    //prevent page reload
    return false;
  }

  @action
  route(path: Url, data?: object) {
    console.log(path, data);
    this.page = path;
    if (data) {
      this.page_data = data;
    }
  }

  @action
  update(field: string, value: string | boolean | Url) {
    this[field] = value;
  }
}

const Tabs = () => (
  <div className="tabs is-toggle is-fullwidth bottomnav">
    <ul>
      <li className="is-active">
        <a>
          <span className="icon is-small">
            <i className="fa fa-calendar" />
          </span>
          <span>Month</span>
        </a>
      </li>
      <li>
        <a>
          <span className="icon is-small">
            <i className="fa fa-list" />
          </span>
          <span>Day</span>
        </a>
      </li>
      <li>
        <a>
          <span className="icon is-small">
            <i className="fa fa-plus" />
          </span>
          <span>Create</span>
        </a>
      </li>
      {
        <li>
          <a>
            <span className="icon is-small">
              <i className="fa fa-etsy" />
            </span>
            <span>Documents</span>
          </a>
        </li>
      }
    </ul>
  </div>
);

const getParams = (query: string) => {
  if (!query) {
    return {};
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split("&")
    .reduce((params, param) => {
      let [key, value] = param.split("=");
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
      return params;
    }, {});
};

var getQueryString = function(field: string) {
  var href = window.location.href;
  var reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
  var string = reg.exec(href);
  return string ? string[1] : null;
};

class SentryBoundary extends React.Component<any, any> {
  error: any;

  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
    Sentry.withScope((scope: any) => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>;
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

class YufiView extends React.Component<any, any> {
  yufi: Yufi;

  render() {
    let yufi = new Yufi(this.props);
    return (
      <div>
        <SentryBoundary>
          <BrowserView>
            <YufiViewWeb yufi={yufi} key="web" />
          </BrowserView>
          <MobileView>
            <YufiViewMobile yufi={yufi} key="mobile" />
          </MobileView>
        </SentryBoundary>
      </div>
    );
  }
}

// import { Container, Hero } from "react-bulma-components/full";

@observer
class YufiViewMobile extends React.Component<any, any> {
  day: Day;
  constructor(props: any) {
    super(props);
    this.day = new Day(props.yufi);

    // reaction(
    //   () => props.yufi.logged_in,
    //   (data:any, reaction:any) => {
    //     console.log('what a reaction')
    //     this.day.initialize(props.yufi)
    //   }
    // )
    // this.day = props.yufi.day
    // yufi = props.yufi
  }

  render() {
    let yufi = this.props.yufi;
    // let day = yufi.day
    let day = this.day;

    return (
      <React.Fragment>
        {yufi.loggedin && (
          <section className="hero">
            <div className="hero-body">
              <div className="container">
                <div
                  id="error-notifier"
                  style={{ display: "none" }}
                  className="notification is-danger"
                >
                  <button className="delete" />
                  We experienced an error.
                </div>
                <Routine {...this.props} day={day} />

                {
                  // <div className="section">
                  //   <h1 className="title is-1">Todos</h1>
                  //   <div className="subtitle is-3">The one-offs</div>
                  //   {day.todos &&
                  //     day.todos.map((task: Todo, index: number) => (
                  //       <TodoCheckmark key={task.id} day={this.day} task={task} />
                  //     ))}
                  // </div>
                }

                {
                  // <div className="section">
                  //   <Journal jc={day.jc} />
                  // </div>
                }
              </div>
            </div>

            {
              // <Tabs />
            }
          </section>
        )}
      </React.Fragment>
    );
  }
}

Month;
Calendar;

const YufiViewWeb = observer(({ yufi }: { yufi: Yufi }) => (
  <div>
    <div className="container">
      <div className="level" />
      <div className="columns">
        <div className="column">
          <Menu
            yufi={yufi}
            name="Menu"
            items={[
              {
                name: "Year",
                onClick: () => yufi.route(Url.month),
                active: false
              },
              {
                name: "Quarter",
                onClick: () => yufi.route(Url.month),
                active: false
              },
              {
                name: "Month",
                onClick: () => yufi.route(Url.month),
                active: yufi.page == Url.month
              },
              {
                name: "Week",
                onClick: () => yufi.route(Url.month),
                active: false
              },
              {
                name: "Day",
                active: yufi.page == Url.day,
                onClick: () => yufi.route(Url.day, {})
              }
            ]}
          />
          {yufi.friends.length > 0 && (
            <Menu
              name="Friends / Stakeholders"
              items={[
                {
                  name: "Me",
                  active: yufi.viewing_user == null,
                  onClick: () => yufi.view_friend(null)
                }
              ].concat(
                yufi.friends.map((f: Friend) => ({
                  name: f.friend__username,
                  active: yufi.viewing_user == f.friend__id,
                  onClick: () => yufi.view_friend(f)
                }))
              )}
            />
          )}
        </div>

        <div
          className="column is-four-fifths"
          style={{
            minHeight: 700
          }}
        >
          <div
            id="error-notifier"
            style={{ display: "none" }}
            className="notification is-danger"
          >
            <button className="delete" />
            We experienced an error.
          </div>
          {yufi.loggedin && yufi.page === Url.day && <DayView yufi={yufi} />}
          {yufi.loggedin && yufi.page === Url.month && (
            <MonthView yufi={yufi} />
          )}
          {yufi.loggedin && yufi.page === Url.alpha && <Alpha />}
        </div>
      </div>
    </div>

    <div className="is-hidden-tablet">
      <Tabs />
    </div>
    <div className="container">version: {process.env.REACT_APP_VERSION}</div>
    <Modal gcal_auth_url={"foo"} brain={yufi} modalType={ModalType} />
  </div>
));

export { Yufi, YufiView, Url };
