// import * as React from "react";
import React from "react";
import { range } from "lodash";
import { css } from "./timeline-css";
// import $ from 'jquery'
// import * as $ from "jquery";
import $ from "jquery";

// import * as moment from 'moment'
// import * as moment from 'moment'
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
// import '../../thirdparty/bigcal.css'

import { observer } from "mobx-react";
const cn: any = require("classnames");
import { Icon } from "../../atoms/icon";
import { Button } from "../../atoms/button";
import { rpc } from "../../fn/rpc";
import { observable, action, when } from "mobx";
import BigCalendar from "react-big-calendar";

import { toJS } from "mobx";

window["moment"] = moment;

enum Service {
  GoogleCalendar,
  Google,
  FacebookCalendar
}

interface Event {
  timeslot?: number;
  summary: string;
  link: string;
  // start: moment.Moment
  // end: moment.Moment
  allDay?: boolean;
  start: Date;
  end: Date;
  title: string;
}

let pc = (...args: any[]) => {
  args.map(a => {
    console.log(JSON.stringify(toJS(a), null, 2));
  });
};
pc;

class TimelineController {
  services = Service;
  @observable events: Event[] = [];
  // @observable authenticated = false
  @observable authenticated = true;
  @observable date = moment();
  // @observable authenticated_services: Service[] = []
  // @observable yufi: Yufi

  constructor(date: moment.Moment) {
    this.date = date;

    // when('we authenticate', () => this.authenticated, () => this.get_events())
    this.get_events();

    rpc("v1/", {
      operation: "read",
      type: "routine",
      query: {
        dayact__date: this.date,
        completed: true
        // tz: new Date().getTimezoneOffset()
      },
      fields: ["task__todo", "modified"]
    }).then((data: any) => {
      this.create_events(
        data.map((d: any) => {
          let time = moment(d.modified);
          return {
            // timeslot: time.hour(),
            summary: d.task__todo,
            link: "",

            start: time.toDate(),
            end: time
              .clone()
              .add(30, "minutes")
              .toDate(),
            title: d.task__todo
          };
        })
      );
    });

    rpc("v1/", {
      operation: "read",
      type: "todo",
      query: {
        modified__date: this.date,
        completed: true
      },
      fields: ["todo", "modified"]
    }).then((data: any) => {
      this.create_events(
        data.map((d: any) => {
          let time = moment(d.modified);
          return {
            timeslot: time.hour(),
            summary: d.todo,
            link: "",

            start: time.toDate(),
            end: time
              .clone()
              .add(30, "minutes")
              .toDate(),
            title: d.todo
          };
        })
      );
    });
  }

  @action
  create_events(events: Event[]) {
    this.events = this.events.concat(events);
  }

  scroll() {
    let timeline = $("timeline");
    if (timeline) {
      let height = timeline.outerHeight();
      if (height) {
        let push = height / 2;
        setTimeout(() => timeline.scrollTop(push), 500);
      }
    }
  }

  get_events = () => {
    console.log("the date was");
    console.log("the date was");
    console.log("the date was");
    console.log("the date was");
    console.log("the date was");
    console.log("the date was");
    console.log(this.date.toDate());
    let this_date = this.date;
    console.log("the date was");
    console.log("the date was");
    console.log("the date was");
    rpc("/", { operation: "get_calendar_events" }).then((data: any) => {
      console.log("was get events calleD?");
      console.log(data);
      let events: Event[] = [];
      data.events.map((event: any) => {
        let start = moment(event.start.dateTime).tz("America/Los_Angeles");
        let end = moment(event.end.dateTime).tz("America/Los_Angeles");
        // TODO: really need to get tz info straight
        events.push({
          start: start.toDate(),
          end: end.toDate(),
          allDay: false,
          title: event.summary,
          summary: event.summary,
          link: event.htmlLink
        });
        console.log(events);
        this.create_events(events);
        // setTimeout(() => this.scroll(), 0)
      });
    });
  };
}

let localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

interface SlotInfo {
  start: Date;
  end: Date;
  slots: Array<Date>;
  action: "select" | "click";
}

const rangeFormat = (...args: any[]) => {
  return "";
};

const Timeline = observer((props: any) => {
  // console.log("rerendered timeline", props.tc.events.length);
  // console.log("rerendered timeline", props.tc.events.length);
  // console.log("rerendered timeline", props.tc.events.length);
  // console.log("rerendered timeline", props.tc.events.length);
  // console.log("rerendered timeline", props.tc.events.length);
  // console.log("rerendered timeline", props.tc.date);
  return (
    <div>
      {
        <BigCalendar
          localizer={localizer}
          date={props.tc.date.toDate()}
          events={props.tc.events}
          startAccessor="start"
          endAccessor="end"
          defaultView="day"
          views={["day", "month"]}
          toolbar={false}
          formats={{
            eventTimeRangeFormat: rangeFormat
          }}
          selectable={true}
          onSelectSlot={(slotInfo: SlotInfo) => {}}
        />
      }
    </div>
  );
});

export { Timeline as default, TimelineController };
