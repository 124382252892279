import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";

import "promise/polyfill";
import "whatwg-fetch";
import "./bulma.scss";
import "./_variables.sass";
import "./styles.css";

import $ from "jquery";
// import Sentry from "@sentry/browser";
import { Header } from "./molecules/header";
import { LandingPage } from "./pages/landing_page";
import { Login } from "./pages/login";
import { Settings } from "./pages/settings";
import { Action } from "./pages/action";
import { Day } from "./pages/day";
import { Eav } from "./pages/eav";

// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     dsn: process.env.SENTRY_DSN
//   });
// }

import { Yufi, YufiView } from "./yufi";
import { Example } from "./components/example";

const react_app_div = $("#react-app");
const react_component_name = react_app_div.data("componentName");
const props = react_app_div.data("props");

const component_registry = {
  Action: Action,
  Login: Login,
  Settings: Settings,
  LandingPage: LandingPage,
  Example: Example,
  Header: Header,
  Day: Day,
  Eav: Eav,
  Yufi: YufiView
};

if (component_registry[react_component_name] === undefined) {
  throw `You are looking for component ${react_component_name}, but it is not defined in the component registry.`;
}

ReactDOM.render(
  React.createElement(component_registry[react_component_name], props, null),
  react_app_div[0]
);
