import { observable, action, toJS } from 'mobx'
import * as React from 'react'
import { observer } from 'mobx-react'
// import * as moment from 'moment'
import { Yufi } from '../yufi'
import { Day } from './day'
import { merge } from 'lodash'
// import { Button } from '../atoms/button'
// import { Menu } from '../molecules/menu'
import { rpc } from '../fn/rpc'

class Creator {
  @observable goals: Goal[] = []
  example_goal = {
    label: '',
    count: 1,
    time_range: 'daily'
  }
  @observable goal: Goal = this.example_goal
  constructor(yufi: Yufi) {
    console.log(yufi)
    rpc('v1/', {
      operation: 'read',
      type: 'goals',
      viewing_user: yufi.viewing_user,
      query: {},
      fields: ['label', 'count', 'time_range']
    }).then(action((data: Goal[]) => (this.goals = data)))

    // tight coupling to a request and what you want

    // rpc('/', { operation: 'retrieve_goals' }).then(
    //   action('retrieve_goals', (data: Goal[]) => (this.goals = data))
    // )

    // rpc('/', { operation: 'retrieve_goals' }).then(
    //   action('retrieve_goals', (data: Goal[]) => (this.goals = data))
    // )
  }

  @action
  update_goal = (dict: {}) => {
    let new_goal = merge(toJS(this.goal), dict)
    // console.log(new_goal)
    this.goal = new_goal
    // console.log(toJS(this.goal))
  }

  @action
  create_goal = (day: Day) => {
    let gn = this.goal.label
    rpc('/', {
      operation: 'create_goal',
      goal: this.goal
    }).then((resp: any) => {
      day.create_task({
        label: gn,
        pk: resp.id,
        state: day.task_states.notstarted
      })
    })
    this.goals.push(this.goal)
    this.goal = this.example_goal
  }

  @action
  update(field: string, value: string | boolean) {
    this[field] = value
  }
}

interface Goal {
  label: string
  count: number
  time_range: string
}

// this is a nasty pattern
const register = (yufi: Yufi) => {
  if (!window['creator']) {
    // console.log('register called')
    let app = new Creator(yufi)
    window['creator'] = app
    return app
  }
  return window['creator']
}

const CreatorView = observer(
  ({
    yufi,
    day,
    creator = register(yufi)
  }: {
    yufi: Yufi
    day: Day
    creator?: Creator
  }) => (
    <div>
      <div className="columns">
        <div className="column">
          {
            // <div className="title">Goal Creator</div>
          }
          <div className="field has-addons ">
            <div className="control" style={{ width: '40px' }}>
              <input type="text" className="input" value="Do" disabled />
            </div>
            <div className="control">
              <input
                className="input"
                type="number"
                min={1}
                placeholder="1"
                onChange={e =>
                  creator.update_goal({
                    count: e.target.value
                  })}
                value={creator.goal.count}
              />
            </div>
            <div className="control">
              <input
                className="input control is-expanded"
                value={creator.goal.label}
                onChange={e => creator.update_goal({ label: e.target.value })}
                placeholder="task"
                type="text"
                onKeyPress={e =>
                  e.key === 'Enter' ? creator.create_goal(day) : 0}
              />
            </div>
            <div className="control">
              <div className="select">
                <select
                  onChange={e =>
                    creator.update_goal({ time_range: e.target.value })}
                  defaultValue="daily"
                >
                  <option value="hourly">hourly</option>
                  <option value="daily">daily</option>
                  <option value="weekly">weekly</option>
                  <option value="monthly">monthly</option>
                </select>
              </div>
            </div>
            <button
              className="button is-info"
              onClick={e => creator.create_goal(day)}
            >
              Add Goal
            </button>
          </div>
          {creator.goals.map((goal: Goal, i: number) => (
            <div key={i}>
              <li>
                <span>{goal.label} </span>
                <span>{goal.count} times </span>
                <span>{goal.time_range} </span>
              </li>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
)

export { Creator, CreatorView }
