import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import {
  Container,
  Section,
  Title,
  Button,
  Level,
  LevelLeft,
  LevelRight,
  LevelItem
} from "../atoms/base";
import _ from "lodash";
import { rpc } from "../fn/rpc";
import { uuidv4 } from "../atoms/utils";

interface Attribute {
  id: string;
  name: string;
  slug: string;
  datatype: string;
}

// interface Value {
//   name: string;
//   value: any;
// }

class EavStore {
  @observable attributes: Attribute[] = [];
  attribute_values = observable.map();

  attrs = (props: any) => {
    let res = _.concat(props.attributes, this.attributes);
    // console.log("res", res);
    return res;
  };

  add_attr = () => {
    this.attributes.push({
      id: uuidv4(),
      name: "",
      slug: "",
      datatype: "int"
    });
  };

  change_name = (attribute: Attribute, name: string) => {
    attribute.name = name;
  };

  change_type = (attribute: Attribute, type: string) => {
    // console.log("changed type");
    attribute.datatype = type;
  };

  set_props = (props: any) => {
    this.attribute_values.replace(JSON.parse(props.task_log.workflowy));
  };

  save_attr = () => {
    rpc("", {
      operation: "attributes",
      attributes: this.attributes
    }).then((data: any) => {
      // console.log(data);
    });
  };

  save_data = () => {
    rpc("", {
      operation: "save_data",
      attribute_values: this.attribute_values
    }).then((data: any) => {
      // console.log(data);
    });
  };
}

const store = new EavStore();

const Eav = observer((props: any) => {
  // console.log(props);
  store.set_props(props);
  return (
    <Container>
      <Section>
        <Level>
          <LevelLeft>
            <LevelItem>
              <Title>Model for '{props.task.todo}'</Title>
            </LevelItem>
          </LevelLeft>
          <LevelRight>
            <LevelItem>
              <a href="/">Back</a>
            </LevelItem>
          </LevelRight>
        </Level>

        {store.attrs(props).map((attribute: any) => {
          return <Attribute attribute={attribute} />;
        })}
        <Button onClick={(e: any) => store.add_attr()}>+ Add Property</Button>
        <Button onClick={(e: any) => store.save_attr()}>Save Properties</Button>
      </Section>

      <Section>
        <Title>Log</Title>

        {store.attrs(props).map((attribute: any) => {
          return <LogAttribute attribute={attribute} />;
        })}

        <Button onClick={(e: any) => store.save_data()}>Log it!</Button>
      </Section>
    </Container>
  );
});

const LogAttribute = observer((props: any) => {
  // console.log(props.attribute.datatype);
  return (
    <div>
      <div>{props.attribute.name}</div>
      <div>
        {props.attribute.datatype == "int" && (
          <input
            type="number"
            value={store.attribute_values.get(props.attribute.slug)}
            onChange={(e: any) => {
              // console.log("change", e.target.value);
              store.attribute_values.set(props.attribute.slug, e.target.value);
            }}
          />
        )}
        {props.attribute.datatype == "text" && (
          <input
            type="text"
            value={store.attribute_values.get(props.attribute.slug)}
            onChange={(e: any) => {
              // console.log("change", e.target.value);
              store.attribute_values.set(props.attribute.slug, e.target.value);
            }}
          />
        )}
      </div>
    </div>
  );
});

const Attribute = observer((props: any) => {
  // console.log(props.attribute.datatype);
  return (
    <div>
      <input
        type="text"
        value={props.attribute.name}
        onChange={(e: any) =>
          store.change_name(props.attribute, e.target.value)
        }
      />
      <select
        value={props.attribute.datatype}
        onChange={(e: any) =>
          store.change_type(props.attribute, e.target.value)
        }
      >
        <option value="int">integer</option>
        <option value="text">text</option>
        <option value="enum">enum</option>
        {
          // <option value="float">float</option>
          // <option value="date">date</option>
          // <option value="bool">boolean</option>
          // <option value="object">object</option>
        }
      </select>
    </div>
  );
});

export { Eav };
