import React from "react";
// import { observable, action } from "mobx";
import SwipeableViews from "react-swipeable-views";
import { observer } from "mobx-react";
// import { rpc } from "../fn/rpc";
// import { Yufi, Url } from "../yufi";
// import cn from "classnames";
// import { Urls } from "../meta/urls";
// import { isMobile } from "react-device-detect";

// Action

import { types, onSnapshot } from "mobx-state-tree";

interface IAction {
  name: string;
}

const ActionModel = types
  .model("Action", {
    name: types.string,
    done: false
  })
  .actions(self => ({
    toggle() {
      self.done = !self.done;
    }
  }));

interface ActionProps {
  actions: IAction[];
}

const styles = {
  slide: {
    padding: 15,
    minHeight: 100,
    color: "#fff"
  },
  slide1: {
    background: "#FEA900"
  },
  slide2: {
    background: "#B3DC4A"
  },
  slide3: {
    background: "#6AC0FF"
  }
};

const Action = observer((props: ActionProps) => {
  console.log(props);
  return (
    <div className="container">
      {props.actions.map(action => {
        return (
          <SwipeableViews>
            <div style={Object.assign({}, styles.slide, styles.slide1)}>
              {action.name}
            </div>
            <div
              className="columns is-mobile"
              style={Object.assign({}, styles.slide, styles.slide2)}
            >
              <span className="column has-background-info">Log</span>
              <span className="column has-background-success">Done</span>
              <span className="column has-background-danger">Fail</span>
              <span className="column has-background-warning">Skip</span>
            </div>
            <div style={Object.assign({}, styles.slide, styles.slide3)}>
              slide n°2
            </div>
          </SwipeableViews>
        );
      })}
    </div>
  );
});

export { Action };
