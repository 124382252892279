import * as React from 'react'

function hoverable(WrappedComponent: any, propName = 'hover'): any {
  return class HoverableComponent extends React.Component {
    state: any
    constructor(props: any) {
      super(props)

      this.state = { hovered: false }
    }

    turnHoverOn() {
      this.setState({ hovered: true })
    }

    turnHoverOff() {
      this.setState({ hovered: false })
    }

    render() {
      const props = { [propName]: this.state.hovered, ...this.props }
      return (
        <span
          onMouseEnter={() => this.turnHoverOn()}
          onMouseLeave={() => this.turnHoverOff()}
        >
          <WrappedComponent {...props} />
        </span>
      )
    }
  }
}
export { hoverable }
