import { observable, action, toJS } from 'mobx'
// import { computed } from 'mobx'
import { uuidv4 } from '../../atoms/utils'
// import 'zepto'
// var $ = require('zepto').$
// import $ from 'jquery'
// import * as $ from 'jquery'

interface Node {
  id: string
  name: string
  children: string[]
  expanded: boolean
  parent: string | null
  referenced_by: string[]
  el?: HTMLElement
  collapsed?: boolean
  // parent: Node | null
}

interface Crumb {
  id: string
  name: string
  onClick(): any
  editable?: boolean
}

interface BreadcrumbProps {
  crumbs: Crumb[]
  active?: number
  crumbstyle?: object
  onChange?: (e: any, crumb: Crumb) => any
}

interface WfVis {
  id: string
  el?: HTMLElement
  level: number
}

enum WorkflowySave {
  waiting,
  saving,
  saved
}

interface SavedDoc {
  root: Node
  nodes: Map<string, Node>
}

class Workflowy {
  // saveddoc = SavedDoc
  @observable visible_inputs: WfVis[] = []
  @observable breadcrumbs: string[] = []
  @observable wf_save = WorkflowySave.waiting
  save_states = WorkflowySave
  // nodes: Map<string, Node> = new Map()
  nodes: Map<string, Node> = observable(new Map())
  focused: Map<string, boolean> = observable(new Map())

  @observable current_node: string
  @observable root: string
  saver: (wf: Workflowy) => void

  // @computed
  children(id: string) {
    // this.nodes[id].children
    return this.nodes.get(id)!.children
  }

  @action
  transition(state: WorkflowySave) {
    this.wf_save = state
  }

  node(id: string) {
    return toJS(this.nodes.get(id))
  }

  get root_node() {
    return this.node(this.current_node)
  }

  constructor(doc: SavedDoc, save: (wf: Workflowy) => void) {
    this.saver = save

    let n = this.convert(doc.root)
    this.nodes.set(n.id, n)

    this.visible_inputs.push({
      id: n.id,
      level: 0
    })

    Object.keys(doc.nodes).map(key => {
      let observable_node = this.convert(doc.nodes[key])
      this.nodes.set(key, observable_node)

      this.visible_inputs.push({
        id: key,
        level: 0
      })
    })

    this.current_node = n.id
    this.root = this.current_node
    this.breadcrumbs.push(n.id)

    let _handler = (e: any) => {
      if ((e.metaKey || e.ctrlKey) && e.keyCode == 83) {
        /*ctrl+s or command+s*/
        this.save()
        // alert('saved?')
        e.preventDefault()
        return false
      }
      return true
    }

    if (!window['registered_keys']) {
      document.addEventListener('keydown', _handler)
      window['registered_keys'] = true
    }
  }

  convert(node: Node) {
    return {
      id: node.id,
      name: node.name,
      expanded: node.expanded,
      children: observable(node.children),
      parent: node.parent,
      referenced_by: node.referenced_by
    }
  }

  save() {
    // wf.wf_save = save_states.saving
    this.saver(this)
  }

  get_document() {
    let r = toJS(this.nodes.get(this.root))
    delete r!['el']
    let rn = toJS(r)

    let new_map = new Map()
    this.nodes.forEach((v, k, map) => {
      let reg = toJS(v)
      delete reg['el']
      new_map[k] = reg
    })
    let nodes = toJS(new_map)
    // console.log(nodes)
    // pa('nodes', nodes)
    // pa(rn)
    return { root: rn, nodes: nodes }
  }

  @action
  create_node(parent: string | null, referenced_by = []) {
    return observable({
      id: uuidv4(),
      name: '',
      expanded: true,
      children: [],
      parent: parent,
      referenced_by: referenced_by
    })
  }

  @action
  create_child(parent: string | null, index: number) {
    let nn = this.create_node(parent)
    // this.nodes[nn.id] = nn
    this.nodes.set(nn.id, nn)
    if (parent) {
      // this.children(parent).indexOf(nn.id)
      this.children(parent).splice(index, 0, nn.id)
    }
    this.focus(nn.id)

    this.visible_inputs.push({
      id: nn.id,
      level: 0
    })
    return nn

    // this.breadcrumbs.push(nn.id)
    // this.current_node.children.push(new_node.id)
    // this.visible_inputs.push({
    //   node: new_node,
    //   level: 0
    // })
    // // debugger
    // setTimeout(() => {
    //   this.visible_inputs[0].el!.focus()
    // }, 0)
  }

  @action
  focus(n: string) {
    this.focused.set(n, true)
  }

  @action
  blur(n: string) {
    this.focused.set(n, false)
  }

  @action
  onChange(e: any, node: string) {
    let dis_node = this.nodes.get(node)
    dis_node!.name = e.target.value
    // this.nodes[node].name = e.target.value
    // this.nodes[node].name = e.target.value
    // console.log('srsly?')
    // i.name = e.target.value
  }

  // @action
  // update_item(new_item: string) {
  //   this.item = {
  //     id: this.item.id,
  //     name: new_item,
  //     children: this.item.children
  //   }
  // }

  @action
  add_item() {
    // this.items.push(this.item)
    // this.current_node.children.push(this.item)
    // // console.log(this.current_node.children.map((c: Node) => c.name))
    // this.item = {
    //   id: uuidv4(),
    //   name: '',
    //   children: []
    //   // parent: this.current_node
    // }
    // console.log('item added')
  }

  @action
  dive(target: string) {
    this.current_node = target
    let parent = this.node(target)!.parent
    let crumbs = []
    if (target != this.root) {
      crumbs.push(target)
    }
    while (parent != this.root && parent) {
      crumbs.push(parent)
      parent = this.node(parent)!.parent
    }
    crumbs.push(this.root)
    this.breadcrumbs = crumbs.reverse()
  }

  @action
  select(n: string) {
    this.dive(n)
  }

  @action
  update_crumb = (e: any, crumb: Crumb) => {
    // let dis = this.breadcrumbs.filter(c => crumb.id == c)
    console.log(crumb.id)
    this.nodes.get(crumb.id)!.name = e.target.value
    // this.nodes[crumb.id].name = e.target.value
  }

  @action
  remove_child(parent: string, id: string) {
    let nc = this.children(parent).filter(x => x != id)
    this.nodes.get(parent)!.children = nc
  }

  @action
  shift_in(node_id: string) {
    let node = this.nodes.get(node_id)
    let parent_id = node!.parent
    if (parent_id) {
      let parent_node = this.nodes.get(parent_id)
      let node_index = parent_node!.children.indexOf(node_id)
      let new_parent_id = parent_node!.children[node_index - 1]
      if (!new_parent_id) {
        return
      }
      node!.parent = new_parent_id
      this.children(new_parent_id).push(node_id)
      this.remove_child(parent_id, node_id)
    }
  }

  @action
  delete_node(node_id: string) {
    let node = this.nodes.get(node_id)
    if (node && node.parent) {
      this.remove_child(node.parent, node_id)
      this.nodes.delete(node_id)
    }
  }

  @action
  collapse(node_id: string) {
    let node = this.nodes.get(node_id)
    if (!node) {
      return
    }
    if (node.expanded) {
      node.expanded = false
    } else {
      node.expanded = true
    }
  }

  @action
  wf_hotkeys(e: any, index: number, node: string) {
    let dis = this.nodes.get(node)
    if (!dis) {
      // Should never happen
      return
    }

    if (e.key == 'Enter') {
      if (!dis.parent) {
        return
      }
      let idx = this.children(dis.parent).indexOf(node)
      let created = this.create_child(dis.parent, idx + 1)
      setTimeout(
        action(() => {
          // hacking over TS
          created['el'].focus()
        }),
        0
      )
    }
    if (e.keyCode == 46 || e.keyCode == 8) {
      if (dis.name !== '') {
        // Do nothing, delete some chars
        return
      }
      // alert('got em')
      this.delete_node(node)
    }

    if (e.keyCode == 9) {
      e.preventDefault()
      if (e.shiftKey) {
        // wf.current_node.children[index - 1].push(i)
        // wf.current_node.children.splice(index, 1)
      } else {
        this.shift_in(node)
      }
    }

    if (e.key == 'ArrowUp') {
      let rows = document.getElementsByClassName('workflowy-row')
      let index = Array.prototype.findIndex.call(
        rows,
        (r: HTMLElement) => r.dataset.nodeId == node
      )
      if (index - 1 >= 0 && index - 1 < rows.length) {
        let prev = rows[index - 1]['dataset']['nodeId']
        this.nodes.get(prev)!['el']!.focus()
      }
    }
    if (e.key == 'ArrowDown') {
      let rows = document.getElementsByClassName('workflowy-row')
      let index = Array.prototype.findIndex.call(
        rows,
        (r: HTMLElement) => r.dataset.nodeId == node
      )
      if (index + 1 >= 0 && index + 1 < rows.length) {
        let next = rows[index + 1]['dataset']['nodeId']
        this.nodes.get(next)!['el']!.focus()
      }
    }
  }
  // }
}

let pa = (...args: any[]) => {
  args.map(a => {
    alert(JSON.stringify(toJS(a), null, 2))
  })
}
pa

let pc = (...args: any[]) => {
  args.map(a => {
    console.log(JSON.stringify(toJS(a), null, 2))
  })
}
pc

export { Workflowy, Node, uuidv4, BreadcrumbProps }
