import * as React from "react";


// 'ExampleProps' describes the shape of props.
// State is never set so we use the '{}' type.

export class Example extends React.Component {
  render() {
    console.log(this.props)
    return (
      <div>
        <h1>Example Component </h1>
        <div>
          <div>Would have recieved the following props: </div>
          <div> { JSON.stringify(this.props, null, 4) } </div>
        </div>
      </div>
    );
  }
}
