import * as React from 'react'
import { observer } from 'mobx-react'
const cn: any = require('classnames')

const style = {
  progress: {
    '&::-webkit-progress-value': {
      transition: 'all 0.5s ease'
    }
  }
}

let jal: any
let sheet: any
if (window['jal']) {
  jal = window['jal']
  sheet = jal.createStyleSheet(style)
} else {
  const jss: any = require('jss')
  const preset: any = require('jss-preset-default')
  jal = jss.create().setup(preset.default())
  sheet = jal.createStyleSheet(style)
  window['jal'] = jal
}

sheet.attach()
const css = sheet.classes

const Progress = observer(
  ({ lower, upper }: { lower: number; upper: number }) => (
    <div>
      <progress
        className={cn('progress', css.progress, {
          'is-success': lower / upper > 0.7,
          'is-danger': lower / upper <= 0.3,
          'is-warning': lower / upper <= 0.7
        })}
        value={lower}
        max={upper}
      />
      {
        // <span className="box">B+</span>
      }
    </div>
  )
)
export { Progress }
