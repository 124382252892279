import React from "react";
import { Container, Box, Title, Subtitle, Section } from "../atoms/base";
import cn from "classnames";

import "./settings.scss";

const Settings = (props: any) => {
  return (
    <Container>
      <Section>
        <Title> Integrations</Title>
      </Section>
      <Section>
        <Subtitle>Task Managers</Subtitle>
        <IntegrationBox name="Asana" connected={props.asana_token_connected}>
          <img
            src="https://marker.io/vendor/img/logo/inverse/asana-logo-white.svg"
            alt=""
          />
        </IntegrationBox>
      </Section>
      <Section>
        <Subtitle>Health Trackers</Subtitle>
        <IntegrationBox
          connected={props.fitbit_oauth_connected}
          url={props.fitbit_auth_uri}
          name=""
        >
          <img
            height={65}
            width={150}
            src="https://assets.fitbit.com/production/109d9919a44b792ebe64e520c3b57dc2.svg"
            alt=""
          />
        </IntegrationBox>
      </Section>
      <Section>
        <Subtitle>Calendars</Subtitle>
        <IntegrationBox
          connected={props.google_oauth_connected}
          name=""
          url={props.google_auth_uri}
        >
          <img
            height={65}
            width={150}
            src="https://www.gstatic.com/images/branding/googlelogo/svg/googlelogo_clr_74x24px.svg"
            alt=""
          />
        </IntegrationBox>
      </Section>
    </Container>
  );
};

const IntegrationBox = (props: any) => {
  return (
    <Box
      className={cn("integration", { connected: props.connected })}
      onClick={(e: any) => {
        if (!props.connected) {
          window.location.href = props.url;
        }
      }}
    >
      <div>{props.children}</div>
      <div>{props.name}</div>
      {props.connected && (
        <div>
          <i className="fa fa-check" aria-hidden="true" />
          <span>Connected</span>
        </div>
      )}
    </Box>
  );
};

export { Settings };
