import * as React from 'react'
const cn: any = require('classnames')

import { merge } from 'lodash'

const Icon = (props: any) => (
  <span
    className={cn('icon', props.className, { 'has-text-info': props.blue })}
    style={merge(props.border ? { border: '1px solid grey' } : {}, props.style)}
    onClick={props.onClick}
  >
    <i className={`fa ${props.name}`} aria-hidden="true" />
  </span>
)

export { Icon }
