import React from "react";

const LandingPage = (props: any) => {
  return (
    <div>
      <section className="hero is-medium is-bold is-light">
        <div className="hero-body">
          <div className="container">
            <h1 className="title intro-title">
              Balance, fullfillment, and mindfulness
            </h1>
            <h2 className="subtitle">
              Tooling that helps you focus on what matters
            </h2>
            <button className="button is-large is-info">Try for Free</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export { LandingPage };
