import React, { Component } from "react";
import cn from "classnames";
import { observer } from "mobx-react";

const BulmaModifiers = (props: any) => {
  return {
    "is-primary": props.primary,
    "is-secondary": props.secondary,
    "is-link": props.link,
    "is-light": props.light,
    "has-shadow": props.has_shadow,
    "is-info": props.info,
    "is-success": props.success,
    "is-warning": props.warning,
    "is-danger": props.danger,
    "is-small": props.small,
    "is-medium": props.medium,
    "is-large": props.large,
    "is-outlined": props.outlined,
    "is-loading": props.loading,
    "is-spaced": props.spaced,
    "is-1": props.one,
    "is-2": props.two,
    "is-3": props.three,
    "is-4": props.four
  };
};

const BulmaClassName = (name: string, props: any) => {
  return cn(name, props.className, BulmaModifiers(props));
};

// Will need to mess with this a bit more.
const BulmaComponent = (element: any, bulmaClassName: any) =>
  observer(props => {
    let {
      primary,
      link,
      info,
      success,
      warning,
      danger,
      light,
      has_shadow,
      small,
      medium,
      large,
      outlined,
      loading,
      spaced,
      one,
      two,
      three,
      four,
      className,
      ...new_props
    } = props;
    return React.createElement(
      element,
      { className: BulmaClassName(bulmaClassName, props), ...new_props },
      props.children
    );
  });

const Container = BulmaComponent("div", "container");
const Section = BulmaComponent("div", "section");
const Title = BulmaComponent("h1", "title");
const Subtitle = BulmaComponent("h4", "subtitle");
const Notification = BulmaComponent("div", "notification");
const Button = BulmaComponent("a", "button");

const Level = BulmaComponent("div", "level");
const LevelItem = BulmaComponent("div", "level-item");
const LevelLeft = BulmaComponent("div", "level-left");
const LevelRight = BulmaComponent("div", "level-right");

const Field = BulmaComponent("div", "field");
const Control = BulmaComponent("p", "control");
const Input = BulmaComponent("input", "input");
const Box = BulmaComponent("div", "box");

const Spacer = (props: any) => {
  return (
    <React.Fragment>
      <br />
      <br />
    </React.Fragment>
  );
};

export {
  Section,
  Title,
  Subtitle,
  Notification,
  Spacer,
  Button,
  Level,
  LevelItem,
  LevelLeft,
  LevelRight,
  Field,
  Control,
  Input,
  Container,
  Box
};
