import { observer } from 'mobx-react'
import * as React from 'react'
import { Day, Todo, DrawerType } from '../pages/day'

import { hoverable } from '../interactions/hover'
const cn: any = require('classnames')
cn
import { Icon } from '../atoms/icon'
Icon
import { action } from 'mobx'

const Todos = observer(({ day }: { day: Day }) => (
  <div className="column">
    <div className="box">
      <h2 className="subtitle">
        <span className="icon ">
          <i className="fa fa-tasks" aria-hidden="true" />
        </span>
        {'  Todos'}
      </h2>
      <div className="field has-addons">
        <div className="control">
          <input
            autoFocus
            className="input"
            type="text"
            placeholder="add todo"
            value={day.todo}
            onChange={e => day.update('todo', e.target.value)}
            onKeyPress={e => (e.key === 'Enter' ? day.create_todo() : 0)}
          />
        </div>
        <div className="control">
          <button className="button is-info" onClick={e => day.create_todo()}>
            Add
          </button>
        </div>
      </div>
      <div id="todos">
        {day.todos
          .sort((a, b) => {
            if (
              day.completed_todos.includes(a.id) &&
              day.completed_todos.includes(b.id)
            ) {
              return 0
            } else if (
              day.completed_todos.includes(a.id) &&
              !day.completed_todos.includes(b.id)
            ) {
              return -1
            } else {
              return 1
            }
          })
          .map((task: Todo, index: number) => (
            <TodoCheckmark day={day} task={task} />
          ))}
      </div>
    </div>
  </div>
))

const TodoCheckmark = observer(
  hoverable(({ day, task, ...props }: { day: Day; task: Todo; props: any }) => (
    <div>
      {
        // <span
        //   className={cn('button is-small', {
        //     'is-info': day.completed_todos.includes(task.id)
        //   })}
        //   onClick={e =>
        //     day.update_todo({
        //       updatetodo: {
        //         key: task.id,
        //         value: true
        //       }
        //     })}
        // >
        //   <Icon name="fa-check" />
        // </span>
      }

      <input
        key={task.id}
        type="checkbox"
        data-task={task.id}
        onChange={e =>
          day.update_todo({
            updatetodo: {
              key: task.id,
              value: e.target.checked
            }
          })}
        defaultChecked={day.completed_todos.includes(task.id)}
      />
      <span
        onClick={action(e => {
          // console.log('clicked')
          day.drawer = DrawerType.todo
        })}
      >
        {task.label}
      </span>
      {props['hover'] && (
        <span>
          <span className="icon" onClick={e => day.remove_todo(task)}>
            <i className="fa fa-times" />
          </span>
        </span>
      )}
    </div>
  ))
)

export { Todos, TodoCheckmark }
