import { observable, action, reaction, toJS, configure } from "mobx";
import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import "moment-timezone";
import { Yufi, Url } from "../yufi";
import { rpc, get } from "../fn/rpc";
import { merge } from "lodash";
import { isEqual } from "lodash";
import Timeline, { TimelineController } from "../molecules/timeline/timeline";
import { Routine } from "../molecules/routine";
import { Todos } from "../molecules/todos";
import { Modal } from "../molecules/modal";
import { Journal, JournalController } from "../molecules/journal";
import { Workflowy, Node } from "../molecules/workflowy/workflowy";
import { PanelContent } from "../molecules/workflowy/wfview";

import { uuidv4 } from "../atoms/utils";

import Drawer from "@material-ui/core/Drawer";
import { CreatorView } from "../pages/creator";
// import correct_sound from "../correct_sound.mp3";

// configure({
//     enforceActions: 'always'
// });

enum TodoState {
  complete,
  notstarted
}

enum TaskState {
  complete,
  notstarted,
  failed
}

interface Task {
  label: string;
  pk: string;
  state: TaskState;
}

interface Todo {
  id: string;
  label: string;
  state: TodoState;
}

enum ModalType {
  none,
  add_routine
}

enum DrawerType {
  none,
  todo,
  routine
}

class Day {
  @observable current_day = moment();
  @observable tasks: Task[] = [];
  @observable todos: Todo[] = [];
  task_states = TaskState;
  // @observable completed_tasks: Set<String> = new Set()
  @observable completed_tasks: string[] = [];
  @observable failed_tasks: string[] = [];
  @observable completed_todos: string[] = [];
  @observable date_to_view = moment();
  // @observable date_to_view = moment.utc()
  @observable todo: string = "";
  @observable modal = ModalType.none;
  @observable modals = ModalType;
  @observable drawer = DrawerType.none;
  @observable drawers = DrawerType;
  @observable drawer_params: any = {};
  // @observable events: Event[] = []
  @observable yufi: Yufi;
  @observable jc: JournalController;
  @observable tc: TimelineController = new TimelineController(
    this.date_to_view
  );

  constructor(yufi: Yufi) {
    this.yufi = yufi;
    this.initialize(yufi);

    window["tc"] = this.tc;
  }

  @action
  open_drawer(drawer_type: DrawerType, params: object) {
    console.log(params);
    this.drawer = drawer_type;
    this.drawer_params = params;
  }

  @action("initialize")
  initialize(yufi: Yufi) {
    if (document.querySelector(".qoute")) {
      get("https://quotes.rest/qod.json?category=management").then(
        data =>
          (document.querySelector(".quote")!.innerHTML =
            data.contents.quotes[0].quote)
      );
    }

    if (yufi.page_data["day"]) {
      // console.log('page date', yufi.page_data)
      this.date_to_view = yufi.page_data["day"]["date"];
    } else {
      // this.date_to_view = moment.utc()
      this.date_to_view = moment();
    }

    //
    this.jc = new JournalController(this);
    this.tc = new TimelineController(this.date_to_view);
    window["tc"] = this.tc;

    // console.log(this.date_to_view.format('YYYY-MM-DD'))
    rpc("/", {
      operation: "retrieve_task_info",
      date: this.date_to_view.format("YYYY-MM-DD"),
      viewing_user: yufi.viewing_user
    }).then(
      action(
        "retrieve_task_info",
        (data: {
          tasks: string[];
          journal: string;
          todos: string[];
          failed: string[];
        }) => {
          console.log(data.failed);
          this.failed_tasks = data.failed;
          this.completed_tasks = data.tasks;
          this.jc.set_journal(data.journal);
          this.completed_todos = data.todos;
          // console.log('2 got completed tasks')
        }
      )
    );

    // .then(() => {
    rpc("v1/", {
      type: "goals",
      operation: "read",
      date: this.date_to_view.format("YYYY-MM-DD"),
      viewing_user: yufi.viewing_user,
      fields: ["pk", "label"]
    }).then(
      action("retrieve_tasks", (data: Task[]) => {
        this.tasks = data;
        // console.log('3 tasks with data')
      })
    );
    // .then(() => {
    rpc("v1/", {
      // operation: 'retrieve_todos',
      operation: "read",
      type: "todo",
      date: this.date_to_view,
      viewing_user: yufi.viewing_user,
      query: {
        or: [
          {
            modified__date: this.date_to_view
          },
          {
            completed: false
          }
        ]
      },
      fields: ["id", "state", "label"]
    }).then(
      action("retrieve_todos", (data: Todo[]) => {
        // console.log(data)
        this.todos = data;
        // console.log('1 got todos')
      })
    );
    // })
    // })
  }

  @action
  control_modal = (modal: ModalType) => {
    this.modal = modal;
  };

  @action
  update_task = (update: any) => {
    // static hack
    var audio = new Audio("/static/correct_sound.mp3");
    audio.play();
    let data = merge(
      {
        operation: "update_task",
        date: this.date_to_view.format("YYYY-MM-DD")
      },
      update
    );
    rpc("/", data).then(
      action(() => {
        if (update.updatetask.value == true) {
          if (!this.completed_tasks.includes(update.updatetask.key)) {
            this.completed_tasks.push(update.updatetask.key);
          }
          console.log("called");

          this.failed_tasks = this.failed_tasks.filter(
            (x: string) => x != update.updatetask.key
          );
        } else if (update.updatetask.value === undefined) {
          this.failed_tasks = this.failed_tasks.filter(
            (x: string) => x != update.updatetask.key
          );
          this.completed_tasks = this.completed_tasks.filter(
            (x: string) => x != update.updatetask.key
          );
        } else {
          this.completed_tasks = this.completed_tasks.filter(
            (x: string) => x != update.updatetask.key
          );
          this.failed_tasks.push(update.updatetask.key);
        }
      })
    );
  };

  @action
  create_task = (t: Task) => {
    this.tasks.push(t);
  };

  @action
  create_todo = () => {
    rpc("/", { operation: "create_todo", todo: this.todo }).then(
      action("create_todo", (data: any) => {
        this.todos.push({
          id: data.id,
          label: this.todo,
          state: TodoState.notstarted
        });
        this.todo = "";
      })
    );
  };

  @action
  remove_todo = (todo: Todo) => {
    rpc("/", { operation: "remove_todo", todo: todo }).then(
      action(
        "remove_todo",
        action((data: any) => {
          this.todos = this.todos.filter(t => t.id !== todo.id);
          // this.todo = ''
        })
      )
    );
  };

  @action
  remove_task = (task: Task) => {
    rpc("/", { operation: "remove_task", task: task }).then(
      action(
        "remove_task",
        action((data: any) => {
          this.tasks = this.tasks.filter(t => t.pk !== task.pk);
          // this.todo = ''
        })
      )
    );
  };

  @action
  update_todo = (data: any) => {
    rpc(
      "/",
      merge(
        {
          operation: "update_todo",
          date: this.date_to_view.format("YYYY-MM-DD"),
          todo: this.todo
        },
        data
      )
    );
  };

  @action
  update(field: string, value: string | boolean) {
    this[field] = value;
  }
}

const register = action("register", (yufi: Yufi) => {
  // if (!window['day']) {
  // console.log('register called !!! ')
  // I wonder if this will cause this to redo now
  console.log(yufi.viewing_user);
  let app = new Day(yufi);
  window["day"] = app;
  yufi.app = app;
  return app;
  // }
  // return window['day']
});

// let screen_info = ''
// if (screen.width < 500) {
//   screen_info = 'mobile ' + String(screen.width)
// } else {
//   screen_info = 'desktop' + String(screen.width)
// }

interface YufiProps extends React.Props<DayView> {
  yufi: Yufi;
  // day: Day
}

@observer
class DayView extends React.Component<YufiProps, {}> {
  day: Day;

  constructor(props: YufiProps, state: {}) {
    super(props);
    console.log(props);
    console.log("cosntructing something??");

    this.day = register(props.yufi);

    // reaction(
    //   () => props.yufi.viewing_user,
    //   user => (this.day = register(props.yufi))
    // )
    // this.day = day
  }
  componentWillMount() {
    // console.log('will mount')
  }

  shouldComponentUpdate(np: any, ns: any) {
    // console.log('should update??', np, ns)
    return true;
  }

  componentWillReact() {
    // console.log('I will re-render, since the todo has changed!')
  }

  componentWillReceiveProps(nextProps: any) {
    // console.log('what props', nextProps)
  }

  componentDidMount() {
    // console.log('mounted')
    // let qs = document.querySelector
    // console.log(qs('#todos'))
    // setTimeout(() => {
    // let todos = document.querySelector('#todos')
    // let routine = document.querySelector('#routines')
    // let td = document.querySelector('.timelinedrop')
    // let drake = dragula([todos, routine, td], {
    //   copy: true,
    //   copySortSource: true,
    //   isContainer: (el: any) => {
    //     return el.classList.contains('timelinedrop')
    //   }
    // })
    //
    // drake.on('dragend', (el: any) => {
    //   // console.log(el, 'was dropped')
    //   // alert('hello')
    //   // debugger
    //   drake.cancel()
    // })
    // }, 1000)
    // dragula([document.querySelector('#left'), document.querySelector('#right')])
    // we really shouldn't just be modifiying the document
    // but seems to work for now
  }

  render() {
    let yufi = this.props.yufi;
    let day = this.day;
    return (
      <div>
        <SidePanel day={day} />
        <div className="content">
          <div className="level">
            <button
              className="button"
              onClick={e => {
                yufi.route(Url.day, {
                  day: { date: day.date_to_view.clone().subtract(1, "days") }
                });
                //todo this is basically hax
                day.initialize(yufi);
              }}
            >
              <i className="fa fa-chevron-left" />
            </button>
            <h2 id="page-title">
              {day.date_to_view.isSame(moment(), "day") && "Today: "}{" "}
              {day.date_to_view.format("ddd MMM DD YYYY")}
            </h2>

            <button
              className="button "
              onClick={e => {
                yufi.route(Url.day, {
                  day: { date: day.date_to_view.clone().add(1, "days") }
                });
                //todo this is basically hax
                day.initialize(yufi);
              }}
            >
              <i className="fa fa-chevron-right" />
            </button>
          </div>
          <p className="quote" />
        </div>
        <br />
        {false && <KeyMetrics />}
        <div className="tile is-ancestor level ">
          {true && (
            <div className="tile is-3">
              <div className="column">
                {
                  <Timeline
                    tc={day.tc}
                    gcal_auth_url={yufi.django_props!.gcal_auth_url}
                  />
                }
              </div>
            </div>
          )}
          <div className="tile is-vertical">
            <div className="tile">
              <Routine day={day} />
              <Todos day={day} />
            </div>
            <div className="column">
              <Journal jc={day.jc} />
            </div>
          </div>
        </div>
        <Modal
          gcal_auth_url={yufi.django_props!.gcal_auth_url}
          brain={day}
          modalType={ModalType}
          body={<CreatorView day={day} yufi={this.props.yufi} />}
          title="Routine Creator"
        />
      </div>
    );
  }
}

// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript

interface SavedDoc {
  root: Node;
  nodes: Map<string, Node>;
}

@observer
class SidePanel extends React.Component<any, any> {
  @observable wf: Workflowy;

  constructor(props: any) {
    super(props);
    this.get_work();
  }

  get_work() {
    // console.log('this doesnt exist yet', this.props.day.drawer_params)

    let save = (wf: Workflowy): void => {
      wf.transition(wf.save_states.saving);
      // let doc = wf.get_document()
      // pa(doc)
      // debugger
      rpc("/", {
        operation: "update_wf",
        task: this.props.day.drawer_params.task,
        document: wf.get_document(),
        date: this.props.day.date_to_view.format("YYYY-MM-DD")
      })
        .then(resp => console.log(resp))
        .then(() => {
          // setTimeout(() => wf.transition(wf.save_states.saved), 1000)
          setTimeout(() => wf.transition(wf.save_states.waiting), 2000);
        });
    };

    reaction(
      () => this.props.day.drawer_params,
      params =>
        rpc("/", {
          operation: "get_wf",
          task: params.task,
          date: this.props.day.date_to_view.format("YYYY-MM-DD")
        }).then(
          action((doc: SavedDoc) => {
            // console.log(doc)
            // doc = {}
            // pa(doc)
            let test = isEqual(doc, {});
            // test = true
            if (test) {
              uuidv4;
              doc = {
                root: observable({
                  id: params.task.pk,
                  name: params.task.label,
                  expanded: true,
                  children: observable([]),
                  parent: null,
                  referenced_by: observable([])
                  // parent: null
                }),
                nodes: new Map()
              };
            }
            // console.log(doc)
            this.wf = new Workflowy(doc, save);
            let old_document = this.wf.get_document();
            setInterval(() => {
              if (
                isEqual(
                  JSON.stringify(this.wf.get_document()),
                  JSON.stringify(old_document)
                )
              ) {
                console.log("do nothing");
              } else {
                // console.log('should save')
                this.wf.save();
                old_document = this.wf.get_document();
              }
            }, 3000);
          })
        )
    );
  }

  render() {
    // const wf: Workflowy = new Workflowy(day)
    let { day } = this.props;
    let wf = this.wf;
    window["wf"] = this.wf;
    // console.log('rendered sidepanel')
    return (
      <Drawer
        onClose={action(e => {
          day.drawer = DrawerType.none;
        })}
        open={day.drawer != DrawerType.none}
        anchor="right"
      >
        <div style={{ width: window.innerWidth / 2 }}>
          {wf && <PanelContent wf={wf} />}
        </div>
      </Drawer>
    );
  }
}

let pa = (args: any) => {
  alert(JSON.stringify(toJS(args), null, 2));
};
pa;

let pc = (args: any) => {
  console.log(JSON.stringify(toJS(args), null, 2));
};
pc;

const KeyMetrics = () => (
  <div className="box">
    <div className="level">
      <div className="level-item has-text-centered">Key Metrics</div>
    </div>

    <nav className="level">
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Sleep</p>
          <p className="title">6.8 hrs</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Computer</p>
          <p className="title">8 hours</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Heart</p>
          <p className="title">82 bpm</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Cash</p>
          <p className="title">5k</p>
        </div>
      </div>
    </nav>
  </div>
);

export { Day, DayView, DrawerType, Todo, Task };
