import * as React from 'react'
import { observer } from 'mobx-react'
// import { Yufi } from '../yufi'

const cn: any = require('classnames')

// // import { jal } from '../yufi'
// interface modalType {
//   none: any
// }

interface Brain {
  control_modal(modal: any): any
  modal: any
  // django_props: any
}

// I want to be able to specify modalType
// as an enum which must have none as a possibility
// don't have support for nested modals yet

interface ModalProps {
  // visible: boolean
  brain: Brain
  modalType: any
  body?: any
  title?: any
  gcal_auth_url?: any
}

// enum ModalType {
//   none,
//   feedback,
//   questions,
//   settings
// }

const Modal = observer((props: ModalProps) => {
  if (props.brain.modal == props.modalType.settings) {
    return <IntegrationModal {...props} />
  }
  return (
    <div
      className={cn('modal', {
        'is-active': props.brain.modal !== props.modalType.none
      })}
    >
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{props.title || 'Modal title'}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={e => props.brain.control_modal(props.modalType.none)}
          />
        </header>
        <section className="modal-card-body">{props.body || 'Foo bar'}</section>
        <footer className="modal-card-foot">
          {
            // <button className="button is-success">Save changes</button>
          }
          <button
            className="button"
            onClick={e => props.brain.control_modal(props.modalType.none)}
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  )
})

const IntegrationModal = observer((props: ModalProps) => (
  <div
    className={cn('modal', {
      'is-active': props.brain.modal !== props.modalType.none
    })}
  >
    <div className="modal-background" />
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{'Integrations'}</p>
        <button
          className="delete"
          aria-label="close"
          onClick={e => props.brain.control_modal(props.modalType.none)}
        />
      </header>
      <section className="modal-card-body">
        <label htmlFor="phinput">Phone Number</label>
        <input
          id="phinput"
          style={{ width: 350 }}
          type="text"
          placeholder="e.g. +13482771234"
        />
        <div>
          <h1>Workout Trackers</h1>
          <div
            className="button"
            onClick={() => {
              let url =
                'https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=22CHHX&redirect_uri=http%3A%2F%2Flocalhost:4000%2Ffitbit&scope=activity%20nutrition%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight'
              window.open(url)
            }}
          >
            <img
              height={65}
              width={150}
              src="https://assets.fitbit.com/production/109d9919a44b792ebe64e520c3b57dc2.svg"
              alt=""
            />
          </div>
        </div>
        <div>
          <h1>Calendars</h1>
          <div
            className="button"
            onClick={() => window.open(props.gcal_auth_url)}
          >
            <img
              height={65}
              width={150}
              src="https://www.gstatic.com/images/branding/googlelogo/svg/googlelogo_clr_74x24px.svg"
              alt=""
            />
            Calendar
          </div>
        </div>
      </section>
      <footer className="modal-card-foot">
        {
          // <button className="button is-success">Save changes</button>
        }
        <button
          className="button"
          onClick={e => props.brain.control_modal(props.modalType.none)}
        >
          Cancel
        </button>
      </footer>
    </div>
  </div>
))

export { Modal }
