// import { observable, action, toJS } from 'mobx'
// import * as React from 'react'
import React from "react";

// import * as $ from 'jquery';

import $ from "jquery";
// import { observer } from 'mobx-react'
// import * as moment from 'moment'
// import { Yufi } from '../yufi'
// import { merge } from 'lodash'
// import { Button } from '../atoms/button'
// import { Menu } from '../molecules/menu'
// import rpc from '../fn/rpc'

class Alpha extends React.Component {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {}

  render() {
    // hax to make sure it always gets replaced if available
    window.postMessage("replace", "http://localhost:4000");
    // console.log('ya?')
    return <AlphaView {...this.props} />;
  }
}

const AlphaView = (props: any): any => (
  <div>
    <h1>Alpha</h1>
    <div id="myfitnesspalcontainer">
      <span id="extension-absent">
        You need the extension to see myfitness pal integration
      </span>
      <iframe
        id="myfitnesspal"
        // allow-same-origin={true}
        // src="http://www.myfitnesspal.com/food/diary"
        style={{
          height: "550px",
          width: "750px",
          border: "0",
          overflow: "hidden"
        }}
        scrolling="no"
      />
    </div>
  </div>
);

export { Alpha };

let send_message = function() {
  let element: any = $("myfitnesspal");
  if (element) {
    let myfitiframe: any = element.contentWindow;
    myfitiframe.postMessage("hello", "*");
  }
};
send_message;
// console.log(send_message)
// window.onload = send_message
// window['send_message'] = send_message

// window.onmessage = (evt: any) => {
//   // console.log(evt)
//   alert('onmessage')
//   if (evt.origin == 'http://www.myfitnesspal.com') {
//     alert('got it')
//   }
//   // alert(JSON.stringify(evt))
//   // alert(evt.origin)
//   // alert(JSON.stringify(evt.data))
//   // debugger
// }

window.onmessage = (evt: any) => {
  // console.log(evt)

  // alert('got event')
  if (evt.origin == "http://www.myfitnesspal.com") {
    // alert('got it')
  }
  if (evt.origin == "https://calendar.google.com/calendar/r/day") {
  }

  if (evt.origin == "https://calendar.google.com") {
    // for evt.data.length
    for (var v = 0; v < evt.data.length; v++) {
      let e = evt.data[v];
      if (!e.time) {
        return;
      }
      let num = Number(e.time.split(":")[0]);
      let timeslot = 0;
      if (e.time.slice(-2) == "am") {
        timeslot = num;
      } else {
        timeslot = num + 12;
      }
      window["tc"].create_events([
        {
          timeslot: timeslot,
          summary: e.label,
          link: e.url
        }
      ]);
    }
    let el = document.querySelector("#gcal");
    if (el && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  }
  // alert(JSON.stringify(evt))
  // alert(evt.origin)
  // alert(JSON.stringify(evt.data))
  // debugger
};
