import * as React from 'react'
import { observer } from 'mobx-react'
import { Workflowy, BreadcrumbProps } from './workflowy'
import { merge, isEmpty } from 'lodash'

import { Icon } from '../../atoms/icon'

import { Button } from '../../atoms/button'
import { Level, LevelRight, LevelLeft, LevelItem } from '../../atoms/level'

import { hoverable } from '../../interactions/hover'

const PanelContent = observer(({ wf }: { wf: Workflowy }) => {
  return (
    <div style={{ padding: '2rem' }}>
      <WfHeader wf={wf} />
      <ul>
        {<NodeObserver wf={wf} />}
        {
          // <Divider />
          // showNode(wf, wf.current_node)
          //   wf.children(wf.current_node).map((node_id: string, index: number) => {
          //   let level = 0
          //   let dis_node = wf.nodes.get(node_id)
          // })
        }
      </ul>
      {isEmpty(wf.children(wf.current_node)) && (
        <Button onClick={e => wf.create_child(wf.root, 0)}>+</Button>
      )}
      <WarningDiv />
    </div>
  )
})

// .is-divider[data-content]::after, .is-divider-vertical[data-content]::after {
// background: #fff;
// color: #b5b5b5;
// content: attr(data-content);
// display: inline-block;
// font-size: .75rem;
// padding: .5rem .8rem;
// -webkit-transform: translateY(-1.1rem);
// -ms-transform: translateY(-1.1rem);
// transform: translateY(-1.1rem);
// text-align: center;

const Divider = () => (
  <div
    className="is-divider"
    style={{
      borderTop: '.1rem solid #dbdbdb',
      height: '.1rem',
      margin: '2rem 0',
      textAlign: 'center'
    }}
    data-content="OR"
  />
)
Divider

const WarningDiv = () => (
  <div
    style={{
      position: 'fixed',
      bottom: 20,
      fontWeight: 600,
      color: 'darkred'
    }}
  >
    This is still in its early stages, and will not work perfectly
  </div>
)

// no sure how else to do this
var INDEX = 0
const NodeObserver = observer(({ wf }: { wf: Workflowy }) => {
  if (wf.current_node) {
    return showNode(wf, wf.current_node)
  } else {
    return null
  }
})

NodeObserver

const showNode = (wf: Workflowy, node_id: string, level = 0): any => {
  INDEX = INDEX + 1
  let dis_node = wf.nodes.get(node_id)
  if (!dis_node) {
    // console.log('it wasnt there')
    // console.log('it wasnt there')
    // console.log('it wasnt there')
    // console.log('it wasnt there')
    // console.log('it wasnt there')
    // console.log('it wasnt there')
    // console.log('it wasnt there')
    return null
  }
  // console.log(node_id)
  // console.log('disnode', dis_node)
  // console.log('disnode', dis_node)
  // console.log('disnode', dis_node)
  // console.log('disnode', dis_node)
  // console.log('disnode', dis_node)
  // console.log('disnode', dis_node)
  return (
    <div>
      {node_id != wf.current_node && (
        <Wflevel node={node_id} wf={wf} level={level} index={INDEX} />
      )}
      {dis_node.expanded &&
        dis_node.children.map((child_node: string, i: number) =>
          showNode(wf, child_node, level + 1)
        )}
    </div>
  )
}

const WfHeader = observer(({ wf }: { wf: Workflowy }) => (
  <Level>
    <LevelLeft>
      <LevelItem>
        <Breadcrumb
          crumbs={wf.breadcrumbs.map(c => {
            return {
              id: c,
              name: wf.nodes.get(c)!.name,
              onClick: () => wf.select(c),
              editable: c == wf.current_node
            }
          })}
          crumbstyle={{
            fontSize: '3rem'
          }}
          onChange={wf.update_crumb}
        />
      </LevelItem>
    </LevelLeft>
    <LevelRight>
      <LevelItem>
        <Button
          done={wf.wf_save == wf.save_states.saved}
          loading={wf.wf_save == wf.save_states.saving}
          onClick={(e: any) => wf.save()}
        >
          Save
        </Button>
      </LevelItem>
    </LevelRight>
  </Level>
))

const Breadcrumb = observer((props: BreadcrumbProps) => (
  <LevelItem>
    {props.crumbs.map((crumb, i) => {
      if (crumb.editable) {
        return (
          <span key={i}>
            <HiddenInput
              style={merge(
                {
                  color: '#3273dc',
                  // padding: '.5em .75em',
                  width: `${3 * crumb.name.length}rem`,
                  textOverflow: 'ellipsis'
                },
                props.crumbstyle
              )}
              value={crumb.name}
              onChange={(e: any) => props.onChange && props.onChange(e, crumb)}
            />
            {i != props.crumbs.length - 1 && <Icon name="fa-chevron-right" />}
          </span>
        )
      } else {
        return (
          <span>
            <a onClick={(e: any) => crumb.onClick()} style={props.crumbstyle}>
              {crumb.name}
            </a>
            {i != props.crumbs.length - 1 && <Icon name="fa-chevron-right" />}
          </span>
        )
      }
    })}
  </LevelItem>
))

const HiddenInput = hoverable((props: any) => (
  <input
    type="text"
    ref={r => {
      if (props.ref_getter) {
        props.ref_getter(r)
      }
    }}
    {...props}
    style={merge(
      {
        border: 'none',
        outline: 'none',
        backgroundColor:
          props.hover || props.focused ? '#f5f5f5' : 'transparent'
      },
      props.style
    )}
  />
))
// HiddenInput2

const Wflevel = observer(
  hoverable((props: any) => {
    //index
    let { node, wf, level } = props
    let n = wf.node(node)
    // let pn = ''
    if (n) {
      let result = wf.node(n.parent)
      if (result) {
        // pn = result.name
      }
    }

    return (
      <div
        style={{
          paddingLeft: 20 * level,
          position: 'relative'
        }}
      >
        {props.hover &&
          n.children.length >= 1 && (
            <Icon
              style={{
                position: 'absolute',
                left: '0px'
              }}
              onClick={(e: any) => wf.collapse(node)}
              name={n.expanded ? 'fa-minus' : 'fa-plus'}
            />
          )}
        <Icon
          style={{
            border: !n.expanded ? '5px solid rgba(50, 115, 220, .3)' : 'none',
            borderRadius: 8

            // backgroundColor: !n.expanded ? 'blue' : 'none'
          }}
          onClick={(e: any) => wf.dive(node)}
          name="fa-square"
        />
        <WFInput {...props} />
      </div>
    )
  })
)

const WFInput = observer((props: any) => {
  // index
  let { node, wf, index } = props
  // let i = props.i
  // let index = props.index
  // let wf = props.wf
  // console.log(wf)
  // console.log('why is this taking so long to render')
  // debugger
  let id = node
  let n = wf.nodes.get(node)
  // let n = wf.nodes[node]
  // if (!n) {
  //   // n = wf.create_node()
  // }
  // let index = 0
  return (
    <HiddenInput
      className="workflowy-row"
      ref_getter={(dis: HTMLElement) => {
        if (dis) {
          n.el = dis
          // wf.visible_inputs[index].el = dis
        }
      }}
      data-node-id={node}
      focused={wf.focused.get(id) == true}
      onFocus={(e: any) => wf.focus(id)}
      onBlur={(e: any) => wf.blur(id)}
      value={n.name}
      onChange={(e: any) => wf.onChange(e, id)}
      onKeyDown={(e: any) => wf.wf_hotkeys(e, index, id)}
    />
  )
})

// const DivCE = (props: any) => <div contentEditable={true}>{props.content}</div>

export { PanelContent }
