import * as React from "react";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { Progress } from "../atoms/progress";
import { Icon } from "../atoms/icon";
const cn: any = require("classnames");
import { get_sheet } from "../atoms/styling";
import SwipeableViews from "react-swipeable-views";
import { BrowserView, MobileView } from "react-device-detect";

import MediaQuery from "react-responsive";
import "./routine.scss";

import { Level, LevelRight, LevelLeft, LevelItem } from "../atoms/level";
// import * as moment from 'moment'
// import { rpc } from '../fn/rpc'
import { hoverable } from "../interactions/hover";

//circular import
import { Day } from "../pages/day";

//duplication
enum TaskState {
  complete,
  notstarted
}

interface Task {
  label: string;
  pk: string;
  state: TaskState;
}

const style = {
  move: {
    "&:hover": {
      cursor: "move"
    }
  }
};

const sheet = get_sheet(style);
sheet.attach();
const css = sheet.classes;

const styles = {
  slide: {
    padding: 15,
    minHeight: 100
  },
  slide1: {
    background: "#FEA900",
    color: "#fff"
  },
  complete: {
    background: "#B3DC4A",
    color: "#fff"
  },
  slide3: {
    background: "#6AC0FF",
    color: "#fff"
  }
};

class MRR {
  current_slide = observable.map();
  @observable current_idx = 1;

  get_slide_index = (id: string) => {
    // console.log("get slide index called");
    // console.log(this.current_slide.toJSON());

    if (this.current_slide.has(id)) {
      return this.current_slide.get(id);
    } else {
      return 0;
    }
  };

  set_slide_index = (id: string, idx: number) => {
    this.current_slide.set(id, idx);
  };

  onChangeIndex = (pk: string, value: number) => {
    this.current_slide.set(pk, value);
    // console.log(value);
    // console.log("on change index called");
  };
}

let MRRStore = new MRR();
window["app"] = MRRStore;
// const MobileRoutineRow
const MobileRoutineRow = observer(
  ({ task, day, index }: { day: Day; task: Task; index: number }) => (
    <div>
      <SwipeableViews
        enableMouseEvents={true}
        index={MRRStore.get_slide_index(task.pk)}
        onChangeIndex={idx => MRRStore.onChangeIndex(task.pk, idx)}
      >
        <div
          className={cn("routine-row", {
            complete: day.completed_tasks.includes(task.pk),
            failed: day.failed_tasks.includes(task.pk),
            [task.pk]: true
          })}
          style={Object.assign({}, styles.slide)}
        >
          {task.label}
        </div>
        <div
          className="columns is-mobile"
          style={Object.assign({}, styles.slide, styles.complete)}
        >
          <span
            className="column has-background-info"
            onClick={e => {
              let dv = day.date_to_view.format("YYYY-MM-DD");
              window.location.href = `/task/${task.pk}/${dv}`;
            }}
          >
            Log
          </span>
          <span
            className="column has-background-success"
            onClick={e => {
              day.update_task({
                updatetask: {
                  key: task.pk,
                  value: true
                }
              });

              MRRStore.set_slide_index(task.pk, 0);
              // console.log("set slide homie");
            }}
          >
            Done
          </span>
          <span
            className="column has-background-danger"
            onClick={e => {
              day.update_task({
                updatetask: {
                  key: task.pk,
                  value: false
                }
              });
              MRRStore.set_slide_index(task.pk, 0);
            }}
          >
            Fail
          </span>
          <span
            className="column has-background-warning"
            onClick={e => {
              day.update_task({
                updatetask: {
                  key: task.pk,
                  value: undefined
                }
              });
              MRRStore.set_slide_index(task.pk, 0);
            }}
          >
            Skip
          </span>
        </div>
        <div style={Object.assign({}, styles.slide, styles.slide3)}>
          slide n°2
        </div>
      </SwipeableViews>
    </div>
  )
);

const RoutineRow = hoverable(
  ({
    task,
    day,
    index,
    ...props
  }: {
    day: Day;
    task: Task;
    index: number;
    props: any;
  }) => (
    <div>
      <Level style={{ position: "relative" }}>
        <Icon
          className={css.move}
          name="fa-bars"
          style={{
            position: "absolute",
            left: "-20px",
            visibility: props["hover"] ? "visible" : "hidden"
          }}
        />
        <LevelRight>
          <LevelItem>
            <div className="buttons has-addons">
              <span
                className={cn("button", {
                  "is-danger": day.failed_tasks.includes(task.pk),
                  "is-selected": day.failed_tasks.includes(task.pk)
                })}
                style={{ marginRight: "-2px" }}
                onClick={e =>
                  day.update_task({
                    updatetask: {
                      key: task.pk,
                      value: false
                    }
                  })
                }
              >
                <Icon name="fa-times" />
              </span>
              <span
                className="button "
                style={{ marginRight: "-2px" }}
                // this should be modeled a lil better
                onClick={e =>
                  day.update_task({
                    updatetask: {
                      key: task.pk,
                      value: undefined
                    }
                  })
                }
              >
                <Icon name="fa-minus" />
              </span>
              <span
                className={cn("button ", {
                  "is-info": day.completed_tasks.includes(task.pk),
                  "is-selected": day.completed_tasks.includes(task.pk)
                })}
                onClick={e =>
                  day.update_task({
                    updatetask: {
                      key: task.pk,
                      value: true
                    }
                  })
                }
              >
                <Icon name="fa-check" />
              </span>
            </div>
          </LevelItem>
          <LevelItem>
            <span
              className="subtitle is-3"
              onClick={action(e => {
                // console.log("clicked");
                // day.open_drawer(day.drawers.routine, { task: task });
                // day.drawer = DrawerType.todo
                // console.logo
                let dv = day.date_to_view.format("YYYY-MM-DD");
                window.location.href = `/task/${task.pk}/${dv}`;
              })}
              style={{
                padding: 5,
                backgroundColor: props["hover"]
                  ? "rgba(79,177,242,.2)"
                  : "transparent",
                maxWidth: 250
              }}
            >
              {task.label}
            </span>
          </LevelItem>
        </LevelRight>
        <LevelLeft>
          <LevelItem>
            {props["hover"] && (
              <span>
                <span className="icon" onClick={e => day.remove_task(task)}>
                  <i className="fa fa-times" />
                </span>
              </span>
            )}
          </LevelItem>
        </LevelLeft>
      </Level>
    </div>
  )
);

const Routine = observer(({ day }: { day: Day }) => (
  <div className="column">
    <div className="box">
      <h2 className="subtitle level">
        <span className="level-left">
          <span className="icon ">
            <i className="fa fa-signal" aria-hidden="true" />
          </span>

          {"  Routine"}
        </span>

        <span className="level-right">
          <button
            className="button is-info"
            onClick={e => day.control_modal(day.modals.add_routine)}
          >
            +
          </button>
        </span>
      </h2>

      <div id="routines">
        {day.tasks.map((task: Task, index: number) => (
          <React.Fragment>
            <MobileView>
              <MobileRoutineRow day={day} task={task} index={index} />
            </MobileView>
            <BrowserView>
              <RoutineRow day={day} task={task} index={index} />
            </BrowserView>
          </React.Fragment>
        ))}
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Progress lower={day.completed_tasks.length} upper={day.tasks.length} />
      </div>
    </div>
  </div>
));

export { Routine, RoutineRow };
