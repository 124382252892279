// import 'whatwg-fetch'
// import $ from "jquery";
// import * as $ from 'jquery';

function getCookie(name: string) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2 && parts != undefined) {
    let pop = parts.pop();
    if (pop) {
      let split = pop.split(";");
      if (split) {
        return split.shift();
      }
    }
    return null;
  } else {
    return null;
  }
}

let get = (path: string) => {
  return fetch(path).then(resp => resp.json());
};

let rpc = (path: string, data: object) => {
  let headers = new Headers();
  // let csrf = 'csrf'
  // let other_csrf = document.cookie.split('csrftoken=')[1]
  let csrf = getCookie("csrftoken");
  // console.log(csrf);
  // console.log("this was the csrf token", csrf);
  if (csrf) {
    headers.append("X-CSRFToken", csrf);
  } else {
    alert(" unable to get csrf");
  }

  // month:true,
  // csrftoken:window[csrf],
  return fetch(path, {
    method: "POST",
    credentials: "include",
    headers: headers,
    body: JSON.stringify(data)
  })
    .then(resp => {
      // console.log(resp.ok);
      if (!resp.ok) {
        throw Error(resp.statusText);
      }
      return resp;
    })
    .then(resp => resp.json());
};

export { rpc, get };
