import * as React from 'react'
import { Button } from '../atoms/button'
import { Yufi, Url } from '../yufi'

const cn: any = require('classnames')
import { observer } from 'mobx-react'

Button
Url
Yufi
// const Menu = observer(({ yufi }: { yufi: Yufi }) => (
//   <div>
//     {
//       // <Button onClick={e => yufi.route(Url.create)}>Create</Button>
//     }
//     <Button onClick={e => yufi.route(Url.day)}>Day</Button>
//     <Button onClick={e => yufi.route(Url.month)}>Month</Button>
//     <a href={window.location.origin + '/overlord/logout/'}>Logout</a>
//   </div>
// ))
interface MenuItem {
  name: string
  active: boolean
  onClick(): any
}

const Menu = observer((props: any) => (
  <div className="box">
    <aside className="menu">
      <ul className="menu-list">
        <p className="menu-label">{props.name}</p>
        {props.items.map((item: MenuItem) => (
          <li>
            <a
              className={cn({
                'is-active': item.active
              })}
              onClick={item.onClick}
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </aside>
  </div>
))

export { Menu }
