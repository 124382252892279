import * as React from 'react'

// const Level = (props: any) => <div className="level">{props.children}</div>

const Level = (props: any) => (
  <nav className="level is-mobile" style={props.style}>
    {props.children}
  </nav>
)
const LevelLeft = (props: any) => (
  <div className="level-left">{props.children}</div>
)

const LevelRight = (props: any) => (
  <div className="level-right">{props.children}</div>
)

const LevelItem = (props: any) => (
  <div className="level-item">{props.children}</div>
)

export { Level, LevelLeft, LevelRight, LevelItem }
